import { useEffect, useState } from "react"
import styled from "styled-components"
import { Select, Button, Typography, Input, Empty, Tooltip, Badge, Popconfirm, Form } from "antd"
import {
    DeleteOutlined,
    SaveOutlined,
    RollbackOutlined,
    PlusCircleFilled,
    BorderOuterOutlined,
    DeploymentUnitOutlined,
    LoadingOutlined,
} from "@ant-design/icons"

import type { Config } from "../../coreTypes/config"
import { Centered, Colors, Spaces, StyleHelpers, ItemWithFadeInAnimation, PageWrapper } from "../global"
import type { EnumFormType } from "../../machines/DesignerModel"
import { ItemsListWrapper, BadgeItem } from "./UserGroupsTab"
import { cleanInputNameStartingUpper } from "../../helpers/functions"

const { Title } = Typography

export default function EnumsTabContent(props: {
    projectConfig: Config
    isEnumForm: boolean
    isSavingForm: boolean
    enumForm: EnumFormType
    sendEnumForm: (enumForm?: { name: string; values: string[] }) => void
    sendSaveEnumForm: () => void
    sendDeleteEnum: (enumName: string) => void
    sendCancelEnum: () => void
    changeName: (name: string) => void
    changeValues: (values: string[]) => void
}) {
    const {
        projectConfig,
        isEnumForm,
        isSavingForm,
        enumForm,
        sendEnumForm,
        sendSaveEnumForm,
        sendDeleteEnum,
        changeName,
        sendCancelEnum,
        changeValues,
    } = props

    return (
        <ItemsListWrapper>
            {!projectConfig.enums || Object.keys(projectConfig.enums)?.length === 0 ? (
                !isEnumForm ? (
                    <Centered>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No sub-objects created yet" />
                    </Centered>
                ) : (
                    <></>
                )
            ) : (
                Object.keys(projectConfig.enums).map((enumName, index) => {
                    return (
                        <EnumItem
                            key={enumName}
                            enumName={enumName}
                            index={index}
                            projectConfig={projectConfig}
                            isEnumForm={isEnumForm}
                            enumForm={enumForm}
                            sendEnumForm={sendEnumForm}
                            sendSaveEnumForm={sendSaveEnumForm}
                            sendDeleteEnum={sendDeleteEnum}
                            sendCancelEnum={sendCancelEnum}
                            changeValues={changeValues}
                        />
                    )
                })
            )}

            {!isEnumForm && (
                <Centered>
                    <Button type="primary" size="large" icon={<PlusCircleFilled />} onClick={() => sendEnumForm()}>
                        Create enum
                    </Button>
                </Centered>
            )}

            {isEnumForm && !enumForm.originalName && (
                <EnumEditForm
                    config={projectConfig}
                    enumForm={enumForm}
                    isSavingForm={isSavingForm}
                    sendSaveEnumForm={sendSaveEnumForm}
                    sendCancelEnum={sendCancelEnum}
                    changeName={changeName}
                    changeValues={changeValues}
                />
            )}
        </ItemsListWrapper>
    )
}

const EnumItem = (props: {
    enumName: string
    index: number
    projectConfig: Config
    isEnumForm: boolean
    enumForm: EnumFormType
    sendEnumForm: (enumForm?: { name: string; values: string[] }) => void
    sendSaveEnumForm: () => void
    sendDeleteEnum: (enumName: string) => void
    sendCancelEnum: () => void
    changeValues: (values: string[]) => void
}) => {
    const [isHovered, setIsHovered] = useState(false)
    const { enumName, index, projectConfig, isEnumForm, enumForm, sendEnumForm, sendSaveEnumForm, sendDeleteEnum, sendCancelEnum, changeValues } =
        props

    let enumConnectionsQty = 0
    // count all enumKey references within fields.enumName of projectConfig.entities and projectConfig.users
    for (const e of projectConfig.entities) {
        for (const f of e.fields) {
            if (f.enumName === enumName) enumConnectionsQty++
        }
    }
    for (const u of projectConfig.users) {
        for (const f of u.userData.fields) {
            if (f.enumName === enumName) enumConnectionsQty++
        }
    }
    if (projectConfig.subObjects) {
        for (const so of Object.keys(projectConfig.subObjects)) {
            for (const f of projectConfig.subObjects[so].fields) {
                if (f.enumName === enumName) enumConnectionsQty++
            }
        }
    }

    return (
        <EnumItemWrapper key={enumName} index={index} isHovered={isHovered} isForm={isEnumForm && enumForm.originalName == enumName}>
            <EnumHeader>
                <div className="titleWithIcon">
                    <BorderOuterOutlined style={{ fontSize: "30px", color: Colors.grayDark }} />
                    <Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
                        {enumName}
                    </Title>
                    {enumConnectionsQty > 0 && (
                        <Tooltip
                            mouseEnterDelay={0.4}
                            mouseLeaveDelay={0}
                            title={`Enum is used in ${enumConnectionsQty} field(-s) of entities/users`}
                        >
                            <BadgeItem>
                                <Badge
                                    count={enumConnectionsQty}
                                    style={{
                                        marginLeft: Spaces.small,
                                        backgroundColor: Colors.grayLight,
                                        borderColor: Colors.grayLight,
                                        color: Colors.grayDark,
                                    }}
                                />
                                <DeploymentUnitOutlined style={{ fontSize: "20px", color: Colors.grayDark }} />
                                {/* dependents */}
                            </BadgeItem>
                        </Tooltip>
                    )}
                </div>

                {(!isEnumForm || enumForm.originalName !== enumName) && (
                    <Popconfirm
                        title={
                            <>
                                Are you sure you want to delete?
                                {enumConnectionsQty > 0 && (
                                    <>
                                        <br />
                                        {`This enum is currently used within ${enumConnectionsQty} data entities/users. Remove all dependents before deleting.`}
                                    </>
                                )}
                            </>
                        }
                        onConfirm={() => {
                            sendDeleteEnum(enumName)
                        }}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ disabled: enumConnectionsQty > 0 }}
                    >
                        <Button
                            disabled={isEnumForm}
                            type="primary"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onMouseEnter={() => {
                                setIsHovered(true)
                            }}
                            onMouseLeave={() => {
                                setIsHovered(false)
                            }}
                            onClick={() => {
                                setIsHovered(false)
                            }}
                        />
                    </Popconfirm>
                )}

                {isEnumForm && enumForm.originalName == enumName && (
                    <div className="buttons">
                        <Popconfirm title="Are you sure you want to delete?" onConfirm={sendCancelEnum} okText="Yes" cancelText="No">
                            <Button
                                type="text"
                                shape="circle"
                                icon={<RollbackOutlined />}
                                onMouseEnter={() => {
                                    setIsHovered(true)
                                }}
                                onMouseLeave={() => {
                                    setIsHovered(false)
                                }}
                                onClick={() => {
                                    setIsHovered(false)
                                }}
                            />
                        </Popconfirm>
                        <Button
                            type="primary"
                            shape="round"
                            disabled={JSON.stringify(projectConfig.enums![enumName]) == JSON.stringify(enumForm.values)}
                            icon={<SaveOutlined />}
                            onMouseEnter={() => {
                                setIsHovered(true)
                            }}
                            onMouseLeave={() => {
                                setIsHovered(false)
                            }}
                            onClick={() => {
                                setIsHovered(false)
                                sendSaveEnumForm()
                            }}
                        >
                            Save
                        </Button>
                    </div>
                )}
            </EnumHeader>
            <Select
                // Not sure if it's better to disable or not
                disabled={isEnumForm && enumForm.originalName !== enumName}
                //
                style={{ width: "100%" }}
                mode="tags"
                tokenSeparators={[","]}
                placeholder="Allowed list of values (insert comma separated values)"
                title="Allowed list of values (insert comma separated values)"
                showSearch={false}
                value={!isEnumForm || enumForm.originalName !== enumName ? projectConfig.enums![enumName] : enumForm.values}
                suffixIcon={<></>}
                open={false}
                dropdownRender={() => <div></div>}
                dropdownStyle={{ display: "none" }}
                onFocus={() => {
                    sendEnumForm({ name: enumName, values: projectConfig.enums![enumName] })
                }}
                onChange={(e) => {
                    changeValues(e)
                }}
                // onSelect={(e) => {
                //     console.log("select", e)
                // }}
                // onDeselect={(e) => {
                //     console.log("deselect", e)
                // }}
            />
        </EnumItemWrapper>
    )
}

const EnumEditForm = (props: {
    config: Config
    enumForm: EnumFormType
    isSavingForm: boolean
    sendSaveEnumForm: () => void
    sendCancelEnum: () => void
    changeValues: (values: string[]) => void
    changeName: (name: string) => void
}) => {
    const [nameValue, setNameValue] = useState("")
    const [isHovered, setIsHovered] = useState(false)
    const [enumNameErrMessage, setEnumNameErrMessage] = useState("")

    const { config, enumForm, isSavingForm, sendSaveEnumForm, sendCancelEnum, changeName, changeValues } = props

    useEffect(() => {
        if (Object.keys(config.enums!).filter((name) => name === enumForm.name).length > 0) {
            setEnumNameErrMessage("The same name exists.")
        } else {
            setEnumNameErrMessage("")
        }
        setNameValue(enumForm.name)
    }, [enumForm.name])

    return (
        <EnumItemWrapper index={0} isHovered={isHovered} isForm>
            <EnumHeader>
                <Form.Item validateStatus={enumNameErrMessage.length > 0 ? "error" : ""} help={enumNameErrMessage}>
                    <Input
                        autoFocus
                        placeholder="Name of the enum"
                        title="Name of the enum"
                        onBlur={(e) => {
                            changeName(e.target.value)
                            setNameValue(cleanInputNameStartingUpper(e.target.value))
                        }}
                        onChange={(e) => {
                            setNameValue(e.target.value)
                        }}
                        value={nameValue}
                    />
                </Form.Item>
                <div className="buttons">
                    <Button
                        type="text"
                        shape="circle"
                        icon={<RollbackOutlined />}
                        onMouseEnter={() => {
                            setIsHovered(true)
                        }}
                        onMouseLeave={() => {
                            setIsHovered(false)
                        }}
                        onClick={() => {
                            setIsHovered(false)
                            sendCancelEnum()
                            setNameValue("")
                        }}
                    />
                    <Button
                        disabled={enumForm.name == "" || enumForm.values.length == 0 || enumNameErrMessage.length > 0}
                        loading={isSavingForm}
                        type="primary"
                        shape="round"
                        icon={<SaveOutlined />}
                        onMouseEnter={() => {
                            setIsHovered(true)
                        }}
                        onMouseLeave={() => {
                            setIsHovered(false)
                        }}
                        onClick={() => {
                            setIsHovered(false)
                            sendSaveEnumForm()
                            setNameValue("")
                        }}
                    >
                        Save
                    </Button>
                </div>
            </EnumHeader>
            <Select
                style={{ width: "100%" }}
                mode="tags"
                tokenSeparators={[","]}
                placeholder="Allowed list of values (insert comma separated values)"
                title="Allowed list of values (insert comma separated values)"
                showSearch={false}
                value={enumForm.values}
                suffixIcon={<></>}
                open={false}
                dropdownRender={() => <div></div>}
                dropdownStyle={{ display: "none" }}
                onChange={(e) => {
                    changeValues(e)
                }}
            />
        </EnumItemWrapper>
    )
}

const EnumItemWrapper = styled(ItemWithFadeInAnimation)<{ isHovered: boolean; index: number; isForm: boolean }>`
    display: flex;
    flex-direction: column;

    width: 100%;
    border-radius: ${StyleHelpers.radiusMedium};
    background-color: white;
    box-shadow: ${(props: { isForm?: boolean }) => (props.isForm ? StyleHelpers.accentGlowShadow : StyleHelpers.staticBoxShadow)};
    border: ${(props: { isForm: boolean }) => (props.isForm ? `2px solid ${Colors.primary}` : "unset")};
    padding: ${Spaces.large};

    animation-delay: ${(props: { index: number }) => props.index * 0.15}s; /* delay animation start for each item */
`

const EnumHeader = styled(Form)`
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: ${Spaces.large};
    justify-content: space-between;
    margin-bottom: ${Spaces.normal};

    .titleWithIcon {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: ${Spaces.large};
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: ${Spaces.normal};
    }
`
