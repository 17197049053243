import { useState, useEffect } from "react"
import styled from "styled-components"
import { Dropdown, Empty, Input, Typography, Tooltip, Button, Badge, Popconfirm, Form } from "antd"
import {
    EditOutlined,
    RollbackOutlined,
    PlusCircleFilled,
    DeploymentUnitOutlined,
    ShareAltOutlined,
    DeleteOutlined,
    UnorderedListOutlined,
    EllipsisOutlined,
    LoadingOutlined,
    CheckOutlined,
} from "@ant-design/icons"

import type { EntityField, Config } from "../../coreTypes/config"
import type { SubObjectFormType } from "../../machines/DesignerModel"
import { Centered, Colors, Spaces, StyleHelpers, ItemWithFadeInAnimation, PageWrapper } from "../global"
import { FieldFormProps } from "./FieldForm"
import { ItemsListWrapper, Badges, BadgeItem } from "./UserGroupsTab"
import DataField from "./DataField"
import FieldForm from "./FieldForm"
import { EntityDetails } from "./DataEntitiesTab"
import { MoreWrapper } from "./FieldForm"
import { ButtonGroupWrapper, SecondaryButton } from "./DataEntitiesTab/EntitiesFormWithFields"
import { isEqual } from "lodash"
import { cleanInputNameStartingUpper } from "../../helpers/functions"

const { Title } = Typography

export default function SubObjectTabContent(props: {
    projectConfig: Config
    subObjectForm: SubObjectFormType
    isSubObjectForm: boolean
    isSavingForm: boolean
    sendSubObjectForm: (subObject?: { name: string; fields: EntityField[] }) => void
    sendSaveSubObjectForm: () => void
    sendDeleteSubObject: (subObjectKey: string) => void
    collapseForm: () => void
    isFieldForm: boolean
    sendFieldForm: (field?: EntityField) => void
    fieldFormProps: FieldFormProps
    changeName: (name: string) => void
}) {
    const {
        projectConfig,
        subObjectForm,
        isSubObjectForm,
        isSavingForm,
        sendSubObjectForm,
        sendSaveSubObjectForm,
        sendDeleteSubObject,
        collapseForm,
        isFieldForm,
        sendFieldForm,
        fieldFormProps,
        changeName,
    } = props

    return (
        <ItemsListWrapper>
            {!projectConfig.subObjects || Object.keys(projectConfig.subObjects)?.length === 0 ? (
                !isSubObjectForm ? (
                    <Centered>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No sub-objects created yet" />
                    </Centered>
                ) : (
                    <></>
                )
            ) : (
                Object.keys(projectConfig.subObjects).map((subObjectKey: string, index: number) => {
                    return (
                        <SubObjectListItem
                            index={index}
                            key={subObjectKey}
                            projectConfig={projectConfig}
                            isEditing={isSubObjectForm && subObjectForm.originalSubObject?.name === subObjectKey}
                            isSavingForm={isSavingForm}
                            subObjectKey={subObjectKey}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            subObject={projectConfig.subObjects[subObjectKey]}
                            subObjectForm={subObjectForm}
                            sendSubObjectForm={sendSubObjectForm}
                            sendSaveSubObjectForm={sendSaveSubObjectForm}
                            sendDeleteSubObject={sendDeleteSubObject}
                            collapseForm={collapseForm}
                            isFieldForm={isFieldForm}
                            sendFieldForm={sendFieldForm}
                            fieldFormProps={fieldFormProps}
                            changeName={changeName}
                        />
                    )
                })
            )}

            {isSubObjectForm && !subObjectForm.originalSubObject?.name ? (
                <SubObjectListItem
                    index={0}
                    projectConfig={projectConfig}
                    isEditing={true}
                    isSavingForm={isSavingForm}
                    subObjectForm={subObjectForm}
                    sendSubObjectForm={sendSubObjectForm}
                    sendSaveSubObjectForm={sendSaveSubObjectForm}
                    sendDeleteSubObject={sendDeleteSubObject}
                    collapseForm={collapseForm}
                    isFieldForm={isFieldForm}
                    sendFieldForm={sendFieldForm}
                    fieldFormProps={fieldFormProps}
                    changeName={changeName}
                />
            ) : (
                <Centered>
                    <Button
                        icon={<PlusCircleFilled />}
                        disabled={isSubObjectForm}
                        size="large"
                        type="primary"
                        onClick={() => {
                            sendSubObjectForm()
                            sendFieldForm()
                        }}
                    >
                        Create sub-object
                    </Button>
                </Centered>
            )}
        </ItemsListWrapper>
    )
}

function SubObjectListItem(props: {
    index: number
    projectConfig: Config
    isEditing: boolean
    isSavingForm: boolean
    subObjectKey?: string
    subObject?: {
        fields: EntityField[]
    }
    subObjectForm: SubObjectFormType
    sendSubObjectForm: (subObject?: { name: string; fields: EntityField[] }) => void
    sendSaveSubObjectForm: () => void
    sendDeleteSubObject: (subObjectKey: string) => void
    collapseForm: () => void
    isFieldForm: boolean
    sendFieldForm: (field?: EntityField, connectionFieldNames?: string[]) => void
    fieldFormProps: FieldFormProps
    changeName: (name: string) => void
}) {
    const {
        index,
        projectConfig,
        isEditing,
        isSavingForm,
        subObjectKey,
        subObject,
        subObjectForm,
        sendSubObjectForm,
        sendSaveSubObjectForm,
        sendDeleteSubObject,
        collapseForm,
        isFieldForm,
        sendFieldForm,
        fieldFormProps,
        changeName,
    } = props

    const [isHovered, setIsHovered] = useState(false)

    let subObjectConnectionsQty = 0
    const subObjectConnectionNames: string[] = []
    // count all subObjectKey references within fields.subObjectName of projectConfig.entities and projectConfig.users
    for (const e of projectConfig.entities) {
        for (const f of e.fields) {
            if (f.subObjectName === subObjectKey) {
                subObjectConnectionNames.push(`Data entities -> ${e.entityName} -> ${f.name}`)
                subObjectConnectionsQty++
            }
        }
    }
    for (const u of projectConfig.users) {
        for (const f of u.userData.fields) {
            if (f.subObjectName === subObjectKey) {
                subObjectConnectionNames.push(`User groups -> ${u.groupName} -> ${f.name}`)
                subObjectConnectionsQty++
            }
        }
    }

    const [nameValue, setNameValue] = useState(subObjectForm.subObject.name)
    const [subObjectNameErrMessage, setSubObjectNameErrMessage] = useState("")
    const isModified = !isEqual(subObjectForm.originalSubObject, subObjectForm.subObject)
    const isNewField = subObjectForm.originalSubObject === undefined && subObjectForm.subObject

    useEffect(() => {
        if (isEditing && !isModified) {
            if (subObjectForm.originalSubObject) {
                setNameValue(subObjectForm.originalSubObject.name)
            }
        }
        if (isNewField) {
            if (Object.keys(projectConfig.subObjects!).filter((key) => key === subObjectForm.subObject.name).length > 0) {
                setSubObjectNameErrMessage("The same name exists.")
            } else {
                setSubObjectNameErrMessage("")
            }
        }
        if (!isEditing) {
            setSubObjectNameErrMessage("")
        }
    }, [isEditing, isModified, isNewField])

    useEffect(() => {
        setNameValue(subObjectForm.subObject.name)
    }, [subObjectForm.subObject.name])

    const nameInput = (
        <Form>
            <Form.Item validateStatus={subObjectNameErrMessage.length > 0 ? "error" : ""} help={subObjectNameErrMessage}>
                <Input
                    disabled={subObjectKey !== undefined && subObjectConnectionsQty > 0}
                    placeholder="Sub-object name"
                    title="Sub-object name"
                    size="large"
                    style={{ maxWidth: "250px" }}
                    value={nameValue}
                    autoFocus={!subObjectForm.subObject.name}
                    onBlur={(e) => {
                        changeName(e.target.value)
                        setNameValue(cleanInputNameStartingUpper(e.target.value))
                    }}
                    onChange={(e) => {
                        setNameValue(e.target.value)
                    }}
                />
            </Form.Item>
        </Form>
    )

    const menuItems: any = []
    if (subObjectForm.originalSubObject && subObjectForm.originalSubObject.name === subObjectKey)
        menuItems.push({
            key: "delete",
            label: (
                <Popconfirm
                    title={
                        <>
                            Are you sure you want to delete?
                            {subObjectConnectionsQty > 0 && (
                                <>
                                    <br />
                                    {`This sub-object is currently used within ${subObjectConnectionsQty} data entities/users. Remove all dependents before deleting.`}
                                </>
                            )}
                        </>
                    }
                    onConfirm={() => {
                        sendDeleteSubObject(subObjectKey!)
                        collapseForm()
                    }}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ disabled: subObjectConnectionsQty > 0 }}
                >
                    delete
                </Popconfirm>
            ),
            icon: <DeleteOutlined />,
        })

    const menuItemAction = (menuItem: any) => {
        switch (menuItem.key) {
            case "delete":
                sendDeleteSubObject(subObjectKey!)
                collapseForm() // to reactivate "Create sub-object" button
                break
            case "cancel":
                collapseForm()
                break
        }
    }

    return (
        <SubObjectItemWrapper key={subObjectKey} index={index} isHovered={isHovered} isEditing={isEditing}>
            <MainBody>
                <ShareAltOutlined style={{ fontSize: "30px", color: Colors.grayDark, marginTop: "5px" }} />
                <SubObjectEntityDetails>
                    {isEditing ? (
                        subObjectConnectionsQty > 0 ? (
                            <Tooltip
                                mouseEnterDelay={0.4}
                                mouseLeaveDelay={0}
                                title={`This sub-object is currently used within ${subObjectConnectionsQty} data entities/users.`}
                            >
                                {nameInput}
                            </Tooltip>
                        ) : (
                            nameInput
                        )
                    ) : (
                        <Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
                            {subObjectKey}
                        </Title>
                    )}

                    {subObjectKey && (
                        <Badges>
                            <Tooltip mouseEnterDelay={0.4} mouseLeaveDelay={0} title={`Sub-object contains ${subObject?.fields.length} field(-s)`}>
                                <BadgeItem>
                                    <Badge
                                        count={subObject?.fields.length}
                                        style={{
                                            marginLeft: Spaces.small,
                                            backgroundColor: Colors.grayLight,
                                            borderColor: Colors.grayLight,
                                            color: Colors.grayDark,
                                        }}
                                    />
                                    <UnorderedListOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
                                    {/* fields */}
                                </BadgeItem>
                            </Tooltip>

                            {subObjectConnectionsQty > 0 && (
                                <Tooltip
                                    mouseEnterDelay={0.4}
                                    mouseLeaveDelay={0}
                                    title={`Sub-object is used in ${subObjectConnectionNames.join(", ")} field(-s) of entities/users`}
                                >
                                    <BadgeItem>
                                        <Badge
                                            count={subObjectConnectionsQty}
                                            style={{
                                                marginLeft: Spaces.small,
                                                backgroundColor: Colors.grayLight,
                                                borderColor: Colors.grayLight,
                                                color: Colors.grayDark,
                                            }}
                                        />
                                        <DeploymentUnitOutlined style={{ fontSize: "20px", color: Colors.grayDark }} />
                                        {/* dependents */}
                                    </BadgeItem>
                                </Tooltip>
                            )}
                        </Badges>
                    )}
                </SubObjectEntityDetails>

                <ButtonGroupWrapper>
                    {menuItems.length > 1 && !isModified && (
                        <MoreWrapper>
                            <Dropdown
                                menu={{
                                    items: menuItems,
                                }}
                                placement="bottomRight"
                            >
                                <EllipsisOutlined
                                    onMouseEnter={() => {
                                        setIsHovered(true)
                                    }}
                                    onMouseLeave={() => {
                                        setIsHovered(false)
                                    }}
                                    style={{ fontSize: "20px", color: Colors.grayDark }}
                                />
                            </Dropdown>
                        </MoreWrapper>
                    )}
                    {menuItems.length === 1 && !isModified && (
                        <MoreWrapper style={{ margin: `0 ${Spaces.normal}` }}>
                            {menuItems[0].key === "delete" ? (
                                <Popconfirm
                                    title={
                                        <>
                                            Are you sure you want to delete?
                                            {subObjectConnectionsQty > 0 && (
                                                <>
                                                    <br />
                                                    {`This sub-object is currently used within ${subObjectConnectionsQty} data entities/users. Remove all dependents before deleting.`}
                                                </>
                                            )}
                                        </>
                                    }
                                    onConfirm={() => {
                                        menuItemAction(menuItems[0])
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                    okButtonProps={{ disabled: subObjectConnectionsQty > 0 }}
                                >
                                    <Button type="text" shape="circle" icon={menuItems[0].icon} />
                                </Popconfirm>
                            ) : (
                                <Popconfirm
                                    title="Are you sure you want to perform this action?"
                                    onConfirm={() => {
                                        menuItemAction(menuItems[0])
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="text" shape="circle" icon={menuItems[0].icon} />
                                </Popconfirm>
                            )}
                        </MoreWrapper>
                    )}

                    {((subObjectForm.originalSubObject &&
                        JSON.stringify(subObjectForm.originalSubObject) !== JSON.stringify(subObjectForm.subObject)) ||
                        !subObjectForm.originalSubObject) &&
                        isEditing && (
                            <MoreWrapper style={{ margin: `0 ${Spaces.normal}` }}>
                                <Popconfirm
                                    title="Are you sure you want to cancel without saving changes?"
                                    onConfirm={collapseForm}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="text" shape="circle" icon={<RollbackOutlined />} />
                                </Popconfirm>
                            </MoreWrapper>
                        )}

                    <SecondaryButton
                        isSolid={(!isEditing && !isModified) || (!isEditing && isModified) || isModified}
                        type="primary"
                        shape={isEditing ? "round" : "circle"}
                        disabled={
                            (isEditing && (subObjectForm.subObject.fields.length == 0 || !subObjectForm.subObject.name || isFieldForm)) ||
                            subObjectNameErrMessage.length > 0
                        }
                        loading={isSavingForm}
                        icon={isEditing ? isModified ? <CheckOutlined /> : <RollbackOutlined /> : <EditOutlined />}
                        onMouseEnter={() => {
                            setIsHovered(true)
                        }}
                        onMouseLeave={() => {
                            setIsHovered(false)
                        }}
                        style={{ transition: "none" }}
                        onClick={() => {
                            setIsHovered(false)
                            if (isEditing) {
                                if (isModified) {
                                    sendSaveSubObjectForm()
                                } else {
                                    collapseForm()
                                }
                            } else {
                                sendSubObjectForm({ name: subObjectKey ?? subObjectForm.subObject.name, fields: subObject?.fields ?? [] })
                            }
                        }}
                    >
                        {isEditing ? (isModified ? "Save" : "Back") : ""}
                    </SecondaryButton>
                </ButtonGroupWrapper>
            </MainBody>

            {isEditing && (
                <CollapsableFields>
                    {subObjectForm.subObject.fields.map((field: EntityField, index: number) => {
                        if (isFieldForm && field.name === fieldFormProps.fieldForm.originalField?.name) {
                            return (
                                <FieldForm
                                    key={field.name}
                                    projectConfig={projectConfig}
                                    fields={subObjectForm.subObject.fields}
                                    {...fieldFormProps}
                                />
                            )
                        } else {
                            return (
                                <DataField
                                    key={field.name}
                                    index={index}
                                    field={field}
                                    isFieldForm={isFieldForm}
                                    sendFieldForm={sendFieldForm}
                                    isPermissionForm={false}
                                    isSavingForm={false}
                                />
                            )
                        }
                    })}

                    {isFieldForm && !fieldFormProps.fieldForm.originalField?.name && (
                        <FieldForm
                            mandatory={subObjectForm.subObject.fields.length == 0}
                            projectConfig={projectConfig}
                            fields={subObjectForm.subObject.fields}
                            {...fieldFormProps}
                        />
                    )}
                    {!isFieldForm && (
                        <Centered>
                            <Button icon={<PlusCircleFilled />} type="primary" onClick={() => sendFieldForm(undefined, subObjectConnectionNames)}>
                                Add field
                            </Button>
                        </Centered>
                    )}
                </CollapsableFields>
            )}
        </SubObjectItemWrapper>
    )
}

const SubObjectItemWrapper = styled(ItemWithFadeInAnimation)<{ index: number; isHovered: boolean; isEditing: boolean }>`
    display: flex;
    flex-direction: column;

    width: 100%;
    border-radius: ${StyleHelpers.radiusMedium};
    background-color: ${(props: { isHovered: boolean; isEditing: boolean }) =>
        props.isEditing ? Colors.background : "white"}; // props.isHovered ? Colors.grayLight :
    box-shadow: ${(props: { isEditing?: boolean }) => (props.isEditing ? StyleHelpers.accentGlowShadow : StyleHelpers.staticBoxShadow)};
    border: ${(props: { isEditing: boolean }) => (props.isEditing ? `2px solid ${Colors.primary}` : "unset")};
    padding: ${Spaces.large};

    animation-delay: ${(props: { index: number }) => props.index * 0.15}s; /* delay animation start for each item */
`

const MainBody = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: ${Spaces.xLarge};
`

const SubObjectEntityDetails = styled(EntityDetails)`
    flex-direction: row;
    align-items: start;
    gap: ${Spaces.xLarge};
    order: 0;
`

const CollapsableFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spaces.large};

    margin-top: ${Spaces.large};
    border-top: 1px solid ${Colors.grayNormal};
    padding-top: ${Spaces.large};
`
