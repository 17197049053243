import React, { FC } from "react"
import { Project } from "../../machines/ProjectsMachine"
import styled from "styled-components"
import { Colors, Spaces, StyleHelpers } from "../global"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { Button } from "antd"
import { EditOutlined, PlayCircleOutlined } from "@ant-design/icons"

interface ProjectCardProps {
    project: Project
    isHovered?: number
    setIsHovered?: (value: React.SetStateAction<number>) => void
    index?: number
    current?: any
}

export const ProjectCard: FC<ProjectCardProps> = ({ project, isHovered = -1, setIsHovered = () => {}, index = 0, current = {} }) => (
    <ProjectCardContent isHovered={isHovered === index + 1} key={project.name}>
        <ProjectTitle level={3}>{project.name}</ProjectTitle>
        <ProjectDescription ellipsis={{ rows: 3, expandable: false }}>{project.description || "No description"}</ProjectDescription>
        <ProjectMetaData>
            {project.latestUpdatedAtISOString
                ? `Last update: (v.${project.latestVersion}) ${dayjs(project.latestUpdatedAtISOString).format("YYYY/MM/DD HH:mm")}`
                : "Blank project"}
        </ProjectMetaData>
        <ProjectMetaData>
            {project.deployedAtISOString
                ? `Deployed: (v.${project.deployedVersion}) ${dayjs(project.deployedAtISOString).format("YYYY/MM/DD HH:mm")}`
                : "Not deployed yet"}
        </ProjectMetaData>
        <ButtonWrapper>
            <Link to={`/${project._id}/designer`}>
                <Button
                    disabled={current.matches("projectForm")}
                    icon={<EditOutlined />}
                    type="primary"
                    onMouseEnter={() => setIsHovered(index + 1)}
                    onMouseLeave={() => setIsHovered(-1)}
                    onClick={() => setIsHovered(-1)}
                >
                    Designer
                </Button>
            </Link>
            <Link to={`/${project._id}/simulator`}>
                <Button
                    disabled={current.matches("projectForm") || !project.deployedAtISOString}
                    type="primary"
                    icon={<PlayCircleOutlined />}
                    onMouseEnter={() => setIsHovered(index + 1)}
                    onMouseLeave={() => setIsHovered(-1)}
                    onClick={() => setIsHovered(-1)}
                >
                    Live API
                </Button>
            </Link>
        </ButtonWrapper>
    </ProjectCardContent>
)

export const ProjectCardContent = styled.div<{ isHovered?: boolean; isNew?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${Spaces.small};
    width: 300px;
    height: 250px;
    padding: ${Spaces.large};
    border-radius: ${StyleHelpers.radiusMedium};

    background-color: ${({ isHovered, isNew }) => (isHovered && isNew ? Colors.grayLight : "white")};

    box-shadow: ${({ isNew, isHovered }) => (!isNew && isHovered ? StyleHelpers.lightBoxShadow : StyleHelpers.staticBoxShadow)};
    transform: ${({ isHovered }) => (isHovered ? "scale(1.01)" : "unset")};
`

const ProjectDescription = styled(Paragraph)`
    padding-left: ${Spaces.normal};
    min-height: 66px;
    margin-bottom: 5px !important;
`

const ProjectMetaData = styled.div`
    padding-left: ${Spaces.normal};
    color: ${Colors.grayNormal};
    font-size: 14px;
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    gap: ${Spaces.xLarge};
    margin-top: ${Spaces.normal};
`

const ProjectTitle = styled(Title)`
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    white-space: nowrap;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
`
