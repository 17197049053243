import { useEffect } from "react"
import { Button, Tabs, Typography } from "antd"
import { LoadingOutlined, PlayCircleOutlined, ReadOutlined, RedoOutlined, UserAddOutlined } from "@ant-design/icons"
import { NotificationInstance } from "antd/es/notification/interface"
import { ApiSimulatorTab, ApiDocsTab, ApiUsersTab } from "../components/Simulator"
import { useMachine } from "@xstate/react"
import { simulatorMachine } from "../machines/SimulatorMachine"
import { useParams } from "react-router-dom"
import { Centered, Colors, PageWrapper } from "../components/global"

const { Title } = Typography

export default function SimulatorPage(props: { notificationsApi: NotificationInstance }) {
    const { projectId } = useParams()
    const [current, send] = useMachine(simulatorMachine)

    if (current.context.debug) {
        useEffect(() => {
            const cleanContext: any = { ...current.context }
            delete cleanContext.projectConfig
            delete cleanContext.generatedEndpoints
            console.log(
                `Simulator machine state value changed:\n${JSON.stringify(current.value, null, 4)};\n\n\nWith context:\n${JSON.stringify(
                    cleanContext,
                    null,
                    4
                )}.`
            )
        }, [current.value])
    }

    if (!current.context.notificationsApi) {
        send({ type: "UPDATE_NOTIFICATIONS_API", notificationsApi: props.notificationsApi })
    }

    if (current.matches("none")) {
        if (projectId) {
            send({ type: "FETCH", projectId: projectId })
            return <></>
        } else {
            return (
                <PageWrapper>
                    <Centered vertical>
                        <Title level={2}>Missing project ID in URL...</Title>
                    </Centered>
                </PageWrapper>
            )
        }
    }

    if (current.matches("globalLoading"))
        return (
            <PageWrapper>
                <Centered vertical>
                    <LoadingOutlined style={{ fontSize: "75px", color: Colors.primary }} />
                    <Title level={2}>Loading project endpoints...</Title>
                </Centered>
            </PageWrapper>
        )

    if (current.matches("error"))
        return (
            <PageWrapper>
                <Centered vertical>
                    <Title level={2}>Error loading project endpoints: try again...</Title>
                    <Button
                        type="primary"
                        icon={<RedoOutlined />}
                        onClick={() => {
                            send("TRY_AGAIN")
                        }}
                    >
                        Load project endpoints
                    </Button>
                </Centered>
            </PageWrapper>
        )

    return (
        <Tabs
            activeKey={
                [
                    "simulatorWrapper.gqlSimulator.observeEndpointsList",
                    "simulatorWrapper.gqlSimulator.queryFilter",
                    "simulatorWrapper.gqlSimulator.querySelectorFields",
                    "simulatorWrapper.gqlSimulator.mutationForm",
                    "simulatorWrapper.gqlSimulator.codeSample",
                ].some(current.matches)
                    ? "SIMULATOR"
                    : current.matches("docs")
                      ? "DOCS"
                      : [
                              "apiUsers.selfSignup.credential",
                              "apiUsers.loginSession",
                              "apiUsers.loginSession.sessions",
                              "apiUsers.loginSession.login.loginForm",
                              "apiUsers.loginSession.login.logingIn",
                              "apiUsers.selfSignup.credential",
                              "apiUsers.selfSignup.profile.profileForm",
                              "apiUsers.selfSignup.profile.creatingUser",
                              "apiUsers.selfSignup.confirmation.confirmForm",
                              "apiUsers.selfSignup.confirmation.confirmingEmail",
                              "apiUsers.emailInvitation.checkingState",
                              "apiUsers.emailInvitation.login.loginForm",
                              "apiUsers.emailInvitation.login.logingIn",
                              "apiUsers.emailInvitation.sessions",
                              "apiUsers.emailInvitation.signup.emailAddress",
                              "apiUsers.emailInvitation.signup.profile.profileForm",
                              "apiUsers.emailInvitation.signup.profile.creatingUser",
                              "apiUsers.emailInvitation.signup.confirmation.confirmForm",
                              "apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                          ].some(current.matches)
                        ? "API_USERS"
                        : ""
            }
            centered
            onChange={(key) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                send(key)
            }}
            tabBarStyle={{ zIndex: 2, background: "white" }}
            items={[
                {
                    key: "SIMULATOR",
                    label: (
                        <span>
                            <PlayCircleOutlined style={{ marginRight: "5px", marginTop: "5px" }} />
                            Live API
                        </span>
                    ),
                    children: <ApiSimulatorTab current={current} send={send} notificationsApi={props.notificationsApi} />,
                    disabled: [
                        "apiUsers.loginSession.login.logingIn",
                        "apiUsers.selfSignup.profile.profileForm",
                        "apiUsers.selfSignup.confirmation.confirmForm",
                        "apiUsers.selfSignup.profile.creatingUser",
                        "apiUsers.selfSignup.confirmation.confirmingEmail",
                        "apiUsers.emailInvitation.signup.profile.profileForm",
                        "apiUsers.emailInvitation.signup.profile.creatingUser",
                        "apiUsers.emailInvitation.signup.confirmation.confirmForm",
                        "apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                    ].some(current.matches),
                },
                {
                    key: "DOCS",
                    label: (
                        <span>
                            <ReadOutlined style={{ marginRight: "5px", marginTop: "5px" }} />
                            Tech Docs
                        </span>
                    ),
                    children: <ApiDocsTab current={current} send={send} notificationsApi={props.notificationsApi} />,
                    disabled: [
                        "apiUsers.loginSession.login.logingIn",
                        "apiUsers.selfSignup.profile.profileForm",
                        "apiUsers.selfSignup.confirmation.confirmForm",
                        "apiUsers.selfSignup.profile.creatingUser",
                        "apiUsers.selfSignup.confirmation.confirmingEmail",
                        "apiUsers.emailInvitation.signup.profile.profileForm",
                        "apiUsers.emailInvitation.signup.profile.creatingUser",
                        "apiUsers.emailInvitation.signup.confirmation.confirmForm",
                        "apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                    ].some(current.matches),
                },
                {
                    key: "API_USERS",
                    label: (
                        <span>
                            <UserAddOutlined style={{ marginRight: "5px", marginTop: "5px" }} />
                            API Users
                        </span>
                    ),
                    children: <ApiUsersTab current={current} send={send} notificationsApi={props.notificationsApi} />,
                },
            ]}
        />
    )
}
