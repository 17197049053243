import { FC, useEffect } from "react"
import { Form, Button, Select, Input } from "antd"
import { Colors, Spaces, StyleHelpers } from "../../global"
import { UserOutlined } from "@ant-design/icons"
import { OptionItemWrapper } from "../../Designer/FieldForm"
import { NotificationInstance } from "antd/es/notification/interface"
import styled from "styled-components"
import { SimulatorMachineSend, SimulatorMachineState } from "../../../machines/SimulatorMachine"

const { OptGroup, Option } = Select

interface Props {
    current: SimulatorMachineState
    send: SimulatorMachineSend
    notificationsApi: NotificationInstance
}

export const LoginForm: FC<Props> = ({ current, send, notificationsApi }: Props) => {
    if (!current.context.notificationsApi) {
        send({ type: "UPDATE_NOTIFICATIONS_API", notificationsApi })
    }

    return (
        <FormWrapper
            onFinish={() => {
                send("LOGIN_CALL")
            }}
        >
            <Form.Item name="usergroup" rules={[{ required: true, message: "Please select usergroup!" }]} style={{ marginBottom: Spaces.xLarge }}>
                <Select
                    placeholder="Select user group name..."
                    title="Select user group name..."
                    style={{ width: 225 }}
                    value={current.context.apiAuth.userGroup === "" ? undefined : current.context.apiAuth.userGroup}
                    onSelect={(value) => {
                        send({
                            type: "SELECT_USER",
                            userGroup: value,
                        })
                    }}
                    autoFocus
                    size="large"
                    disabled={current.matches("apiUsers.loginSession.login.logingIn")}
                >
                    {current.context.projectConfig.users && (
                        <OptGroup label="User group" key="user group">
                            {Object.keys(current.context.projectConfig.users).map((un: string) => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                const u = current.context.projectConfig.users[un]
                                return (
                                    <Option value={u.groupName} key={u.groupName}>
                                        <OptionItemWrapper>
                                            <UserOutlined
                                                style={{
                                                    width: StyleHelpers.iconSize,
                                                    color: Colors.grayDark,
                                                }}
                                            />
                                            {u.groupName}
                                        </OptionItemWrapper>
                                    </Option>
                                )
                            })}
                        </OptGroup>
                    )}
                </Select>
            </Form.Item>
            <Form.Item
                name="username"
                rules={[{ required: true, message: "Please input email address of your application's end-user!" }]}
                style={{ marginBottom: Spaces.normal }}
            >
                <Input
                    disabled={current.matches("apiUsers.loginSession.login.logingIn")}
                    autoComplete="off"
                    placeholder="User email address"
                    title="User email address"
                    size="large"
                    value={current.context.apiAuth.loginForm.username}
                    onChange={(e) => {
                        send({ type: "SET_USERNAME", username: e.target.value })
                    }}
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: "Please input your password!",
                    },
                    {
                        pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).+$/,
                        message: (
                            <>
                                Should contain at least 1 uppercase,
                                <br />1 lowercase, 1 digit and 1 special charecter.
                            </>
                        ),
                    },
                    {
                        min: 8,
                        message: "Should be at least 8 characters.",
                    },
                ]}
            >
                <Input.Password
                    disabled={current.matches("apiUsers.loginSession.login.logingIn")}
                    autoComplete="new-password"
                    placeholder="User password"
                    title="User password"
                    size="large"
                    value={current.context.apiAuth.loginForm.password}
                    onChange={(e) => {
                        send({ type: "SET_PASSWORD", password: e.target.value })
                    }}
                />
            </Form.Item>
            <div style={{ margin: "0 auto", marginRight: "0" }}>
                <Button
                    disabled={["apiUsers.loginSession.login.logingIn", "apiUsers.emailInvitation.login.logingIn"].some(current.matches)}
                    size="large"
                    style={{ marginRight: Spaces.normal }}
                    onClick={() => {
                        send("GO_SESSIONS")
                    }}
                >
                    Back to Sessions
                </Button>
                <Button
                    loading={["apiUsers.loginSession.login.logingIn", "apiUsers.emailInvitation.login.logingIn"].some(current.matches)}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{ width: "120px" }}
                >
                    Log In
                </Button>
            </div>
        </FormWrapper>
    )
}

const FormWrapper = styled(Form)`
    display: flex;
    flex-direction: column;
`
