import { FC } from "react"
import { NotificationInstance } from "antd/es/notification/interface"
import { Colors, HalfContainer, Spaces, StyleHelpers } from "../../global"
import { Card, Tabs, Tooltip, Typography } from "antd"
import { ArrowLeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import styled from "styled-components"

import { LoginForm } from "./LoginForm"
import { SignupForm } from "./SignupForm"
import { ActiveSessionsList } from "./ActiveSessionsList"
import { EmailInvitationSignupForm } from "./EmailInvitationSignupForm"
import { TabContentWrapper } from "../../../pages/Designer"
import { APIHeaderSection } from "../ApiSimulatorTab"
import { SimulatorMachineSend, SimulatorMachineState } from "../../../machines/SimulatorMachine"

const { Title, Text } = Typography

interface Props {
    current: SimulatorMachineState
    send: SimulatorMachineSend
    notificationsApi: NotificationInstance
}

export const ApiUsersTab: FC<Props> = ({ current, send, notificationsApi }) => {
    if (!current.context.notificationsApi) {
        send({ type: "UPDATE_NOTIFICATIONS_API", notificationsApi })
    }

    const DisableRecipient = () => {
        return (
            <DisableRecipientWrapper>
                <ContentWrapper>
                    <ArrowLeftOutlined style={{ marginRight: Spaces.large, fontSize: "20px" }} className="signaling-arrow" />
                    <div>
                        <Title level={4} style={{ marginTop: 0 }}>
                            Authorize as Sender
                        </Title>
                        <Text style={{ marginBottom: 0 }}>To start sending email invitations authorize with sender user</Text>
                    </div>
                </ContentWrapper>
            </DisableRecipientWrapper>
        )
    }

    return (
        <ApiUsersTabWrapper>
            <APIHeaderSection>
                <Title level={2} style={{ marginTop: 0, marginBottom: 0 }}>
                    Live API user authentication
                </Title>
            </APIHeaderSection>

            <Tabs
                activeKey={
                    [
                        "apiUsers.loginSession",
                        "apiUsers.loginSession.sessions",
                        "apiUsers.loginSession.login.loginForm",
                        "apiUsers.loginSession.login.logingIn",
                    ].some(current.matches)
                        ? "LOGIN_SESSION"
                        : [
                                "apiUsers.selfSignup.credential",
                                "apiUsers.selfSignup.profile.profileForm",
                                "apiUsers.selfSignup.profile.creatingUser",
                                "apiUsers.selfSignup.confirmation.confirmForm",
                                "apiUsers.selfSignup.confirmation.confirmingEmail",
                            ].some(current.matches)
                          ? "SELF_SINGUP"
                          : [
                                  "apiUsers.emailInvitation.checkingState",
                                  "apiUsers.emailInvitation.login.loginForm",
                                  "apiUsers.emailInvitation.login.logingIn",
                                  "apiUsers.emailInvitation.sessions",
                                  "apiUsers.emailInvitation.signup.emailAddress",
                                  "apiUsers.emailInvitation.signup.profile.profileForm",
                                  "apiUsers.emailInvitation.signup.profile.creatingUser",
                                  "apiUsers.emailInvitation.signup.confirmation.confirmForm",
                                  "apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                              ].some(current.matches)
                            ? "EMAIL_INVITATION"
                            : ""
                }
                onChange={(key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    send("TO_" + key)
                }}
                items={[
                    {
                        key: "LOGIN_SESSION",
                        label: (
                            <Tooltip
                                mouseEnterDelay={1}
                                mouseLeaveDelay={0}
                                title="You can log in as end-user of your application with his own credential."
                            >
                                <Title
                                    disabled={[
                                        "apiUsers.selfSignup.profile.profileForm",
                                        "apiUsers.selfSignup.confirmation.confirmForm",
                                        "apiUsers.selfSignup.profile.creatingUser",
                                        "apiUsers.selfSignup.confirmation.confirmingEmail",
                                        "apiUsers.emailInvitation.signup.profile.profileForm",
                                        "apiUsers.emailInvitation.signup.profile.creatingUser",
                                        "apiUsers.emailInvitation.signup.confirmation.confirmForm",
                                        "apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                                    ].some(current.matches)}
                                    level={4}
                                    style={{ marginTop: 0, marginBottom: 0 }}
                                >
                                    Log In
                                </Title>
                            </Tooltip>
                        ),
                        children: (
                            <TabContentWrapper>
                                <CardWrapper active={current.matches("apiUsers.loginSession.login")}>
                                    {["apiUsers.loginSession.login.loginForm", "apiUsers.loginSession.login.logingIn"].some(current.matches) && (
                                        <LoginForm current={current} send={send} notificationsApi={notificationsApi} />
                                    )}
                                    {current.matches("apiUsers.loginSession.sessions") && <ActiveSessionsList current={current} send={send} />}
                                </CardWrapper>
                            </TabContentWrapper>
                        ),
                        disabled: [
                            "apiUsers.selfSignup.profile.profileForm",
                            "apiUsers.selfSignup.confirmation.confirmForm",
                            "apiUsers.selfSignup.profile.creatingUser",
                            "apiUsers.selfSignup.confirmation.confirmingEmail",
                            "apiUsers.emailInvitation.signup.profile.profileForm",
                            "apiUsers.emailInvitation.signup.profile.creatingUser",
                            "apiUsers.emailInvitation.signup.confirmation.confirmForm",
                            "apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                        ].some(current.matches),
                    },
                    {
                        key: "SELF_SINGUP",
                        label: (
                            <Tooltip
                                mouseEnterDelay={1}
                                mouseLeaveDelay={0}
                                title="Directly sign up as new end-user of your application within allowed for direct sign up user groups (configured in your Quorini project > User groups)"
                            >
                                <Title
                                    disabled={[
                                        "apiUsers.loginSession.login.logingIn",
                                        "apiUsers.emailInvitation.signup.profile.profileForm",
                                        "apiUsers.emailInvitation.signup.profile.creatingUser",
                                        "apiUsers.emailInvitation.signup.confirmation.confirmForm",
                                        "apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                                    ].some(current.matches)}
                                    level={4}
                                    style={{ marginTop: 0, marginBottom: 0 }}
                                >
                                    Direct sign-up
                                </Title>
                            </Tooltip>
                        ),
                        children: (
                            <TabContentWrapper>
                                <CardWrapper active>
                                    <SignupForm current={current} send={send} notificationsApi={notificationsApi} />
                                </CardWrapper>
                            </TabContentWrapper>
                        ),
                        disabled: [
                            "apiUsers.loginSession.login.logingIn",
                            "apiUsers.emailInvitation.signup.profile.profileForm",
                            "apiUsers.emailInvitation.signup.profile.creatingUser",
                            "apiUsers.emailInvitation.signup.confirmation.confirmForm",
                            "apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                        ].some(current.matches),
                    },
                    {
                        key: "EMAIL_INVITATION",
                        label: (
                            <Tooltip
                                mouseEnterDelay={1}
                                mouseLeaveDelay={0}
                                title="One end-user of your application (Sender) can invite another user (Recipient) to sign up over email invitation, if Recipient is invitable by Sender (configured in your Quorini project > User groups)"
                            >
                                <Title
                                    disabled={[
                                        "apiUsers.selfSignup.profile.profileForm",
                                        "apiUsers.selfSignup.confirmation.confirmForm",
                                        "apiUsers.selfSignup.profile.creatingUser",
                                        "apiUsers.selfSignup.confirmation.confirmingEmail",
                                        "apiUsers.loginSession.login.logingIn",
                                    ].some(current.matches)}
                                    level={4}
                                    style={{ marginTop: 0, marginBottom: 0 }}
                                >
                                    Email Invitation
                                </Title>
                            </Tooltip>
                        ),
                        children: (
                            <TabContentWrapperSplitView>
                                <div>
                                    <HeaderWrapper>
                                        <Title level={4}>
                                            Invitation Sender
                                            <Tooltip title="If you want to invite someone, you should log in first as a Sender.">
                                                <QuestionCircleOutlined style={{ marginLeft: Spaces.small, cursor: "help" }} />
                                            </Tooltip>
                                        </Title>
                                    </HeaderWrapper>

                                    <CardWrapper active={current.matches("apiUsers.emailInvitation.login.loginForm")}>
                                        {["apiUsers.emailInvitation.login.loginForm", "apiUsers.emailInvitation.login.logingIn"].some(
                                            current.matches
                                        ) && <LoginForm current={current} send={send} notificationsApi={notificationsApi} />}

                                        {["apiUsers.emailInvitation.sessions", "apiUsers.emailInvitation.signup"].some(current.matches) && (
                                            <ActiveSessionsList current={current} send={send} />
                                        )}
                                    </CardWrapper>
                                </div>

                                <div style={{ position: "relative" }}>
                                    {!current.context.apiAuth.currentAuthSession && <DisableRecipient />}
                                    <HeaderWrapper>
                                        <Title level={4}>
                                            Invitation Recipient
                                            <Tooltip title="Recipient can sign up only with an invitation from a Sender.">
                                                <QuestionCircleOutlined style={{ marginLeft: Spaces.small, cursor: "help" }} />
                                            </Tooltip>
                                        </Title>
                                    </HeaderWrapper>
                                    <CardWrapper active>
                                        <EmailInvitationSignupForm current={current} send={send} notificationsApi={notificationsApi} />
                                    </CardWrapper>
                                </div>
                            </TabContentWrapperSplitView>
                        ),
                        disabled: [
                            "apiUsers.selfSignup.profile.profileForm",
                            "apiUsers.selfSignup.confirmation.confirmForm",
                            "apiUsers.selfSignup.profile.creatingUser",
                            "apiUsers.selfSignup.confirmation.confirmingEmail",
                            "apiUsers.loginSession.login.logingIn",
                        ].some(current.matches),
                    },
                ]}
            />
        </ApiUsersTabWrapper>
    )
}

const ApiUsersTabWrapper = styled(TabContentWrapper)`
    padding-top: 0px;
    background: ${Colors.background};
    gap: ${Spaces.large};
    min-height: calc(100vh - 50px);
`

const TabContentWrapperSplitView = styled.div`
    display: flex;
    flex-direction: row;
    @media (width < 1300px) {
        flex-direction: column;
    }
`

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0 ${Spaces.large};
`

const CardWrapper = styled(Card)<{ active?: boolean }>`
    min-width: 600px;
    width: calc(100% - 2 * ${Spaces.large});
    margin: ${Spaces.xLarge} ${Spaces.large};
    padding: ${Spaces.large};

    border-radius: ${StyleHelpers.radiusMedium};
    border: ${(props: { active?: boolean }) => (props.active ? `2px solid ${Colors.primary}` : "none")};
    box-shadow: ${(props: { active?: boolean }) => (props.active ? StyleHelpers.accentGlowShadow : StyleHelpers.staticBoxShadow)};
    cursor: default;

    &:hover {
        border-radius: ${StyleHelpers.radiusMedium};
        border: ${(props: { active?: boolean }) => (props.active ? `2px solid ${Colors.primary}` : "none")};
        box-shadow: ${(props: { active?: boolean }) => (props.active ? StyleHelpers.accentGlowShadow : StyleHelpers.staticBoxShadow)};
        cursor: default;
    }

    .ant-card-head {
        border-bottom: none;
    }
    .ant-card-body {
        padding: 0;
    }
    .ant-tabs-nav {
        box-shadow: none;
    }
`

const DisableRecipientWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: calc(325px + 2 * 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: ${Colors.transparentDarkFrostyBackground};
    backdrop-filter: ${StyleHelpers.blur};
    border-radius: ${StyleHelpers.radiusSmall};
`

const ContentWrapper = styled.div`
    display: flex;
    background: white;
    padding: ${Spaces.large};
    border-radius: ${StyleHelpers.radiusSmall};
    box-shadow: ${StyleHelpers.accentGlowShadow};
`
