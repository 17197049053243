import { FC } from "react"
import { Spaces, StyleHelpers } from "../../global"
import { Button, Empty, Typography } from "antd"
import { KeyOutlined, LoginOutlined, LogoutOutlined, PlusCircleFilled, SwapOutlined, UserOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { TextTag } from "../ApiSimulatorTab/AuthComponents"
import { SimulatorMachineSend, SimulatorMachineState } from "../../../machines/SimulatorMachine"

const { Text } = Typography

interface Props {
    current: SimulatorMachineState
    send: SimulatorMachineSend
}

export const ActiveSessionsList: FC<Props> = ({ current, send }) => (
    <ActiveSessionsListWrapper>
        <Text type="secondary" style={{ marginTop: Spaces.normal }}>
            Active user sessions:
        </Text>

        {current.context.apiAuth.authSessionsList.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No active sessions yet..." />
        ) : (
            current.context.apiAuth.authSessionsList.map((us, idx: number) => (
                <UserSession key={idx}>
                    <SessionInfo>
                        {us.authType == "USERGROUP" ? (
                            <UserOutlined style={{ fontSize: StyleHelpers.iconSize }} />
                        ) : (
                            <KeyOutlined style={{ fontSize: StyleHelpers.iconSize }} />
                        )}
                        <Text underline>{us.username}</Text>
                        {us.authType == "USERGROUP" && <TextTag>{us.userGroup}</TextTag>}
                    </SessionInfo>
                    {us.username === current.context.apiAuth.currentAuthSession?.username ? (
                        <Button
                            type="primary"
                            size="small"
                            icon={<LogoutOutlined />}
                            onClick={() => {
                                send("SESSION_LOGOUT")
                            }}
                            disabled={[
                                "apiUsers.emailInvitation.signup.profile.profileForm",
                                "apiUsers.emailInvitation.signup.profile.creatingUser",
                                "apiUsers.emailInvitation.signup.confirmation.confirmForm",
                                "apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                            ].some(current.matches)}
                        >
                            Log out
                        </Button>
                    ) : (
                        <Button
                            size="small"
                            icon={current.context.apiAuth.currentAuthSession ? <SwapOutlined /> : <LoginOutlined />}
                            onClick={() => {
                                send("GO_SESSIONS")
                                if (current.context.apiAuth.currentAuthSession) {
                                    send({ type: "SWITCH_SESSION", sessionIndex: idx })
                                } else {
                                    send({ type: "SESSION_LOGIN", sessionIndex: idx })
                                }
                            }}
                            disabled={[
                                "apiUsers.emailInvitation.signup.profile.profileForm",
                                "apiUsers.emailInvitation.signup.profile.creatingUser",
                                "apiUsers.emailInvitation.signup.confirmation.confirmForm",
                                "apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                            ].some(current.matches)}
                        >
                            {current.context.apiAuth.currentAuthSession ? "Switch" : "Log In"}
                        </Button>
                    )}
                </UserSession>
            ))
        )}

        <Button
            icon={<PlusCircleFilled />}
            style={{ margin: `${Spaces.normal} auto` }}
            type="primary"
            onClick={() => {
                send("GO_SESSIONS")
                send("GO_LOGIN")
            }}
            disabled={[
                "apiUsers.emailInvitation.signup.profile.profileForm",
                "apiUsers.emailInvitation.signup.profile.creatingUser",
                "apiUsers.emailInvitation.signup.confirmation.confirmForm",
                "apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
            ].some(current.matches)}
        >
            Log in
        </Button>

        {current.matches("apiUsers.loginSession.sessions") && (
            <div style={{ textAlign: "end", marginTop: Spaces.medium }}>
                <Button
                    size="large"
                    type="primary"
                    loading={["apiUsers.verifyingEmail", "apiUsers.logingIn"].some(current.matches)}
                    onClick={() => {
                        send("GO_SIMULATOR")
                    }}
                >
                    Go to Live API
                </Button>
            </div>
        )}
    </ActiveSessionsListWrapper>
)

const ActiveSessionsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const SessionInfo = styled.div`
    & > * {
        margin-right: ${Spaces.medium};
    }
    display: flex;
`

export const UserSession = styled.div`
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
`
