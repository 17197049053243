import React, { createContext } from "react"
import ReactDOM from "react-dom/client"
import { useInterpret } from "@xstate/react"
import { ActorRefFrom } from "xstate"

import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { authMachine } from "./machines/AuthMachine"

interface GlobalStateContextType {
    authService: ActorRefFrom<typeof authMachine>
}

export const GlobalStateContext = createContext({} as GlobalStateContextType)

export const GlobalStateProvider = (props: { children: JSX.Element }) => {
    const authService = useInterpret(authMachine)

    return (
        <GlobalStateContext.Provider
            value={{
                authService,
            }}
        >
            {props.children}
        </GlobalStateContext.Provider>
    )
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <React.StrictMode>
        <GlobalStateProvider>
            <App />
        </GlobalStateProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
