import styled from "styled-components"
import { Colors, Spaces } from "./global"

const SwitchersGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
`

const Switcher = styled.div<{ selected?: boolean; inactive?: boolean; heavy?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Spaces.small};
    background-color: ${(props: { selected?: boolean; inactive?: boolean }) =>
        props.selected ? (props.inactive ? "unset" : Colors.primary) : props.inactive ? "unset" : Colors.background};
    color: ${(props: { selected?: boolean; inactive?: boolean }) =>
        props.inactive ? Colors.grayNormal : props.selected ? Colors.background : Colors.primary};
    border: ${(props: { inactive?: boolean; heavy?: boolean }) =>
        props.inactive ? `${props.heavy ? "2" : "0"}px solid ${Colors.grayLight}` : `${props.heavy ? "2" : "1"}px solid ${Colors.primary}`};
    font-weight: ${(props: { heavy?: boolean }) => (props.heavy ? "bold" : "normal")};
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 14px;
    cursor: ${(props: { inactive?: boolean }) => (props.inactive ? "unset" : "pointer")};

    &:hover {
        background-color: ${(props: { selected?: boolean; inactive?: boolean }) =>
            props.selected ? (props.inactive ? "unset" : Colors.primaryHover) : props.inactive ? "unset" : Colors.grayLight};
    }
`

export { SwitchersGroup, Switcher }
