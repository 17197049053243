import styled from "styled-components"
import { StyleHelpers, Colors, Spaces } from "../../global"

const AuthSessionsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spaces.medium};
    margin-bottom: ${Spaces.normal};

    max-height: 300px;
    padding: 5px;
    overflow-y: auto;
    overflow-x: visible;
`

const TextTag = styled.div`
    background: ${Colors.grayLight};
    color: ${Colors.grayDark};
    border-radius: ${StyleHelpers.radiusSmall};
    padding: 0 ${Spaces.normal};
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
`

export { AuthSessionsList, TextTag }
