import { useState } from "react"
import styled from "styled-components"
import { Empty, Typography, Tooltip, Button, Badge } from "antd"
import { EditOutlined, PlusCircleFilled, DeploymentUnitOutlined, ApartmentOutlined, ProfileOutlined } from "@ant-design/icons"

import type { EntityField, Config, ConfigEntity } from "../../../coreTypes/config"
import { Centered, Colors, Spaces, ItemWithFadeInAnimation } from "../../global"
import EntitiesFormWithFields, { EntityFormProps } from "./EntitiesFormWithFields"
import { FieldFormProps } from "../FieldForm"
import { ItemsListWrapper, ListItemWrapper, Badges, BadgeItem } from "../UserGroupsTab"

const { Title, Paragraph } = Typography

export default function EntitiesTabContent(props: {
    projectConfig: Config
    isObserveList: boolean
    isEntityForm: boolean
    isFieldForm: boolean
    isPermissionForm: boolean
    sendEntityForm: (entity?: ConfigEntity) => void
    sendFieldForm: (field?: EntityField) => void
    entityFormProps: EntityFormProps
    fieldFormProps: FieldFormProps
}) {
    const {
        projectConfig,
        isObserveList,
        isEntityForm,
        isFieldForm,
        sendEntityForm,
        sendFieldForm,
        entityFormProps,
        fieldFormProps,
        isPermissionForm,
    } = props

    // console.log(JSON.stringify({ entityForm }, null, 4))

    if (isObserveList)
        return (
            <ItemsListWrapper>
                {projectConfig.entities?.length === 0 ? (
                    <Centered>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data entities created yet" />
                    </Centered>
                ) : (
                    projectConfig.entities?.map((entity: ConfigEntity, index: number) => {
                        return <DataEntityListItem sendEntityForm={sendEntityForm} entity={entity} key={entity.entityName} index={index} />
                    })
                )}
                <Centered>
                    <Button
                        icon={<PlusCircleFilled />}
                        size="large"
                        type="primary"
                        onClick={() => {
                            sendEntityForm()
                        }}
                    >
                        Create data entity
                    </Button>
                </Centered>
            </ItemsListWrapper>
        )

    if (isEntityForm)
        return (
            <EntitiesFormWithFields
                isPermissionForm={isPermissionForm}
                projectConfig={projectConfig}
                isFieldForm={isFieldForm}
                sendFieldForm={sendFieldForm}
                fieldFormProps={fieldFormProps}
                {...entityFormProps}
            />
        )

    return <>Unexpected Entities tab UI state</>
}

function DataEntityListItem(props: { entity: ConfigEntity; sendEntityForm: (entity?: ConfigEntity) => void; index: number }) {
    const { entity, sendEntityForm, index } = props
    const [isHovered, setIsHovered] = useState(false)

    const entityConnectionsQty = entity.fields?.reduce((acc, field) => {
        return acc + (field.connectedEntityName ? 1 : 0)
    }, 0)

    return (
        <ListItemWrapper key={entity.entityName} isHovered={isHovered} index={index}>
            <DeploymentUnitOutlined style={{ fontSize: "30px", color: Colors.grayDark }} />
            <EntityDetails>
                <Title level={3} style={{ marginTop: 0 }}>
                    {entity.entityName}
                </Title>
                <Paragraph ellipsis={{ rows: 2, expandable: false }}>{entity.description}</Paragraph>
                <Badges>
                    <Tooltip
                        mouseEnterDelay={0.4}
                        mouseLeaveDelay={0}
                        title={`Entity has ${Object.keys(entity.defaultFields).length + entity.fields?.length} field(-s)`}
                    >
                        <BadgeItem>
                            <Badge
                                showZero
                                count={Object.keys(entity.defaultFields).length + entity.fields?.length}
                                style={{
                                    marginLeft: Spaces.small,
                                    backgroundColor: Colors.grayLight,
                                    borderColor: Colors.grayLight,
                                    color: Colors.grayDark,
                                }}
                            />
                            <ProfileOutlined style={{ fontSize: "20px", color: Colors.grayDark }} />
                        </BadgeItem>
                    </Tooltip>
                    {entityConnectionsQty > 0 && (
                        <Tooltip mouseEnterDelay={0.4} mouseLeaveDelay={0} title={`Entity has ${entityConnectionsQty} connection(-s)`}>
                            <BadgeItem>
                                <Badge
                                    count={entityConnectionsQty}
                                    style={{
                                        marginLeft: Spaces.small,
                                        backgroundColor: Colors.grayLight,
                                        borderColor: Colors.grayLight,
                                        color: Colors.grayDark,
                                    }}
                                />
                                <ApartmentOutlined style={{ fontSize: "20px", color: Colors.grayDark }} />
                            </BadgeItem>
                        </Tooltip>
                    )}
                </Badges>
            </EntityDetails>
            <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onMouseEnter={() => {
                    setIsHovered(true)
                }}
                onMouseLeave={() => {
                    setIsHovered(false)
                }}
                onClick={() => {
                    setIsHovered(false)
                    sendEntityForm(entity)
                }}
            />
        </ListItemWrapper>
    )
}

export const EntityDetails = styled.div`
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    /* Inside auto layout */
    order: 1;
    flex-grow: 1;
`
