import { FC, useState } from "react"
import styled from "styled-components"
import { TabContentWrapper } from "../../../pages/Designer"
import { TextTag } from "./AuthComponents"
import { Colors, HalfContainer, Spaces, StyleHelpers } from "../../global"
import { UserAddOutlined, UserOutlined } from "@ant-design/icons"
import { Button, Empty, Select, Typography } from "antd"
import { EndpointItem } from "./EndpointsComponents"
import { OptionItemWrapper } from "../../Designer/FieldForm"
import ResponseContainer from "./ResponseContainer"
import { NotificationInstance } from "antd/es/notification/interface"
import { SimulatorMachineSend, SimulatorMachineState } from "../../../machines/SimulatorMachine"

const { Title } = Typography
const { Option } = Select

interface Props {
    current: SimulatorMachineState
    send: SimulatorMachineSend
    notificationsApi: NotificationInstance
}

export const ApiSimulatorTab: FC<Props> = ({ current, send, notificationsApi }) => {
    const [sessionDropdownOpen, setSessionDropdownOpen] = useState<boolean>(false)

    if (!current.context.notificationsApi) {
        send({ type: "UPDATE_NOTIFICATIONS_API", notificationsApi })
    }

    return (
        <ApiSimulatorTabWrapper>
            <APIHeaderSection>
                <GQLSimulatorHeader>
                    <Title level={2} style={{ marginTop: 0, marginBottom: 0 }}>
                        Live API
                    </Title>

                    {/* FIXME: add environments => into code constructor [Tasks] */}
                    <Select
                        //value={current.context.gqlSimulator.env}
                        disabled
                        placeholder="Select environment..."
                        title="Select environment..."
                    />

                    {/* FIXME: after implement version control */}
                    {/* <Select
                        //value={current.context.gqlSimulator.version}
                        disabled
                        placeholder="Select version..."
                    /> */}

                    <Select
                        open={sessionDropdownOpen}
                        onDropdownVisibleChange={setSessionDropdownOpen}
                        placeholder="Select an authenticated user..."
                        title="Select an authenticated user..."
                        value={current.context.apiAuth.currentAuthSession?.username}
                        onSelect={(value) => {
                            send({
                                type: "SELECT_SESSION",
                                sessionIndex: Number(value),
                            })
                        }}
                        autoFocus
                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Not active sessions..." />}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: 8,
                                    }}
                                >
                                    <Button
                                        style={{ width: "100%" }}
                                        icon={<UserAddOutlined />}
                                        onClick={() => {
                                            setSessionDropdownOpen(false)
                                            send("API_USERS")
                                        }}
                                    >
                                        Log In / Sign Up
                                    </Button>
                                </div>
                            </>
                        )}
                        style={{ minWidth: "300px" }}
                    >
                        {current.context.apiAuth.authSessionsList.length > 0 &&
                            current.context.apiAuth.authSessionsList.map((un, idx) => (
                                <Option value={idx} key={un.username}>
                                    <OptionItemWrapper>
                                        <UserOutlined
                                            style={{
                                                width: StyleHelpers.iconSize,
                                                color: Colors.grayDark,
                                            }}
                                        />
                                        {un.username}
                                        <TextTag>{un.userGroup}</TextTag>
                                    </OptionItemWrapper>
                                </Option>
                            ))}
                    </Select>
                </GQLSimulatorHeader>
            </APIHeaderSection>
            <Containers>
                <EndpointsContainer shadow={!current.matches("simulatorWrapper.response.hidden")}>
                    <Title level={4} style={{ marginTop: 0, marginBottom: Spaces.normal }}>
                        Queries
                    </Title>

                    {current.context.gqlSimulator.visibleQueryEndpoints?.map((e, ind: number) => {
                        return (
                            <EndpointItem
                                index={ind}
                                key={e.name}
                                type={e.type}
                                entityDescription={e.description}
                                titleName={e.name}
                                isInputOutputAll={e.isInputOutputAll}
                                outputFields={e.outputFields}
                                projectConfig={current.context.projectConfig}
                                entityName={e.entityName}
                                outputSubfields={e.outputSubfields}
                                openFilter={() => {
                                    send({ type: "OPEN_FILTER", endpointName: e.name })
                                }}
                                openSelectorFields={() => {
                                    send({ type: "OPEN_SELECTOR_FIELDS", endpointName: e.name })
                                }}
                                openCodeSample={() => {
                                    send({ type: "OPEN_CODE_SAMPLE", endpointName: e.name })
                                }}
                                selectEndpoint={() => {
                                    send({ type: "SELECT_ENDPOINT", endpointName: e.name })
                                }}
                                closeEndpoint={() => {
                                    send("CLOSE_ENDPOINT")
                                }}
                                collapseAll={() => {
                                    send("COLLAPSE_ALL")
                                }}
                                run={() => {
                                    send({ type: "RUN", endpointName: e.name })
                                }}
                                isAuthenticated={current.context.apiAuth.currentAuthSession !== undefined}
                                isSelected={current.context.gqlSimulator.selectedEndpoint === e.name}
                                isFilter={current.matches("simulatorWrapper.gqlSimulator.queryFilter")}
                                isSelectorFields={current.matches("simulatorWrapper.gqlSimulator.querySelectorFields")}
                                isCodeSample={current.matches("simulatorWrapper.gqlSimulator.codeSample")}
                                selectField={(pathKey: string) => {
                                    send({ type: "SELECT_FIELD", field: pathKey })
                                }}
                                unselectField={(pathKey: string) => {
                                    send({ type: "UNSELECT_FIELD", field: pathKey })
                                }}
                                filterRules={{
                                    filters: current.context.gqlSimulator.endpointsData[e.name]?.filters,
                                    addFilterRule: () => {
                                        send({ type: "ADD_RULE" })
                                    },
                                    removeFilterRule: (ruleIndex: number) => {
                                        send({ type: "REMOVE_RULE", ruleIndex })
                                    },
                                    changeFilterField: (ruleIndex: number, field: string) => {
                                        send({ type: "CHANGE_RULE_FIELD", ruleIndex, field })
                                    },
                                    changeFilterOperator: (ruleIndex: number, operator: string) => {
                                        send({ type: "CHANGE_OPERATOR", ruleIndex, operator })
                                    },
                                    changeFilterValue: (ruleIndex: number, value: string) => {
                                        send({ type: "CHANGE_VALUE", ruleIndex, value })
                                    },
                                }}
                                endpointData={current.context.gqlSimulator.endpointsData[e.name]}
                                setObjectId={(objectId: string) => send({ type: "SET_OBJECTID", endpointName: e.name, objectId })}
                            />
                        )
                    })}

                    <Title level={4} style={{ marginTop: Spaces.xLarge, marginBottom: Spaces.normal }}>
                        Mutations
                    </Title>

                    {current.context.gqlSimulator.visibleMutationEndpoints?.map((e, ind: number) => {
                        let index = current.context.gqlSimulator.visibleQueryEndpoints?.length ?? 0
                        index += ind
                        return (
                            <EndpointItem
                                index={index}
                                key={e.name}
                                type={e.type}
                                entityDescription={e.description}
                                titleName={e.name}
                                isInputOutputAll={e.isInputOutputAll}
                                outputFields={e.outputFields}
                                projectConfig={current.context.projectConfig}
                                entityName={e.entityName}
                                outputSubfields={e.outputSubfields}
                                inputFields={e.inputFields}
                                openSelectorFields={() => {
                                    send({ type: "OPEN_SELECTOR_FIELDS", endpointName: e.name })
                                }}
                                openCodeSample={() => {
                                    send({ type: "OPEN_CODE_SAMPLE", endpointName: e.name })
                                }}
                                openMutationForm={() => {
                                    send({ type: "OPEN_MUTATION_FORM", endpointName: e.name })
                                }}
                                setMutationFormValues={(values: any) => {
                                    send({ type: "SET_MUTATION_FORM_FIELDS_VALUES", values: values })
                                }}
                                mutationFormValues={current.context.gqlSimulator.endpointsData[e.name]?.inputs}
                                selectEndpoint={() => {
                                    send({ type: "SELECT_ENDPOINT", endpointName: e.name })
                                }}
                                closeEndpoint={() => {
                                    send("CLOSE_ENDPOINT")
                                }}
                                collapseAll={() => {
                                    send("COLLAPSE_ALL")
                                }}
                                run={() => {
                                    send({ type: "RUN", endpointName: e.name })
                                }}
                                isAuthenticated={current.context.apiAuth.currentAuthSession !== undefined}
                                isSelected={current.context.gqlSimulator.selectedEndpoint === e.name}
                                isSelectorFields={current.matches("simulatorWrapper.gqlSimulator.querySelectorFields")}
                                isCodeSample={current.matches("simulatorWrapper.gqlSimulator.codeSample")}
                                isMutationForm={current.matches("simulatorWrapper.gqlSimulator.mutationForm")}
                                selectField={(pathKey: string) => {
                                    send({ type: "SELECT_FIELD", field: pathKey })
                                }}
                                unselectField={(pathKey: string) => {
                                    send({ type: "UNSELECT_FIELD", field: pathKey })
                                }}
                                endpointData={current.context.gqlSimulator.endpointsData[e.name]}
                                setObjectId={(objectId: string) => send({ type: "SET_OBJECTID", endpointName: e.name, objectId })}
                                allowedInviteUser={current.context.gqlSimulator.visibleInvitationUserGroups?.includes(e.entityName)}
                            />
                        )
                    })}

                    {/* FIXME: implement subscription endpoints
                        reuse EndpointItem
                        with filters and selectors 
                        add total received events + new unread events counter
                        icon to listen -> then tick icon and pause/stop on hover
                        <RetweetOutlined />
                        <CheckCircleOutlined />
                        <PauseCircleOutlined />
                    */}
                </EndpointsContainer>

                <HalfContainerResponse>
                    {!current.matches("simulatorWrapper.response.hidden") ? (
                        <ResponseContainer
                            isLoading={
                                current.context.gqlSimulator.endpointsData[current.context.gqlSimulator.selectedEndpoint]?.status === "loading"
                            }
                            response={current.context.gqlSimulator.endpointsData[current.context.gqlSimulator.selectedEndpoint]?.response}
                            config={current.context.projectConfig}
                            selectedFields={current.context.gqlSimulator.endpointsData[current.context.gqlSimulator.selectedEndpoint]?.selectorFields}
                            selectedEndpoint={current.context.gqlSimulator.selectedEndpoint}
                            type={
                                current.context.gqlSimulator.selectedEndpoint.startsWith("get")
                                    ? "Get"
                                    : current.context.gqlSimulator.selectedEndpoint.startsWith("list")
                                      ? "List"
                                      : ""
                            }
                        />
                    ) : (
                        <Title level={5} style={{ padding: `0 ${Spaces.large}` }}>
                            Run endpoint to see API response...
                        </Title>
                    )}
                </HalfContainerResponse>
            </Containers>
        </ApiSimulatorTabWrapper>
    )
}

const ApiSimulatorTabWrapper = styled(TabContentWrapper)`
    padding-top: 0px;
`

const HalfContainerResponse = styled(HalfContainer)`
    &:last-child {
        padding: 0;
    }
`

const Containers = styled.div`
    height: calc(100vh - 127px);
    display: flex;
    flex-direction: row;
    align-self: stretch;
    flex-grow: 1;
`

const EndpointsContainer = styled(HalfContainer)<{ shadow: boolean }>`
    box-shadow: ${({ shadow }) => (shadow ? StyleHelpers.boldBoxShadow : "none")};
    background-color: ${Colors.background};
    padding: ${Spaces.medium} ${Spaces.large} 80px;
    overflow-x: hidden;
`

export const APIHeaderSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    padding: ${Spaces.large} ${Spaces.xLarge};
    background: white;
    box-shadow: ${StyleHelpers.boldBoxShadow};
    z-index: 1;
    & > * {
        margin-right: ${Spaces.large};
    }
`

const GQLSimulatorHeader = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${Spaces.large};
    align-items: center;
`
