import { useState } from "react"
import styled from "styled-components"
import {
    CheckCircleFilled,
    EditOutlined,
    FontColorsOutlined,
    FieldNumberOutlined,
    NumberOutlined,
    FieldBinaryOutlined,
    BarsOutlined,
    ShareAltOutlined,
    DeploymentUnitOutlined,
    CalendarOutlined,
    ClockCircleOutlined,
    ApiOutlined,
    MailOutlined,
    PhoneOutlined,
    UserOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons"
import { Button, Tooltip, Typography } from "antd"

import { Colors, Spaces, StyleHelpers, ItemWithFadeInAnimation } from "../global"
import { SwitchersGroup, Switcher } from "../Switcher"
import type { EntityField } from "../../coreTypes/config"

const { Paragraph } = Typography

function getTypeIcon(type: EntityField["type"]) {
    switch (type) {
        case "STRING":
            return <FontColorsOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        case "INT":
            return <FieldNumberOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        case "FLOAT":
        case "DOUBLE":
            return <NumberOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        case "BOOLEAN":
            return <FieldBinaryOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        case "DATE":
            return <CalendarOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        case "TIME":
            return <ClockCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        case "ENUM":
            return <BarsOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        case "SUBOBJECT":
            return <ShareAltOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        case "CONNECTION_BELONGSTO":
        case "CONNECTION_CONTAINS":
            return <DeploymentUnitOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        // case "CUSTOMENDPOINT":
        //     return <SubnodeOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        // case "EXTERNALENDPOINT":
        //     return <GlobalOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        case "USER_ID":
        case "OBJECT_ID":
            return <ApiOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        case "EMAIL":
            return <MailOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        case "PHONENUMBER":
            return <PhoneOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        case "USERSELF":
            return <UserOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
        default:
            return <></>
    }
}

function getTypeText(type: EntityField["type"]) {
    switch (type) {
        case "STRING":
            return "String"
        case "INT":
            return "Integer"
        case "FLOAT":
            return "Float"
        case "DOUBLE":
            return "Double"
        case "BOOLEAN":
            return "Boolean"
        case "DATE":
            return "Date"
        case "TIME":
            return "Time"
        case "ENUM":
            return "Enum"
        case "SUBOBJECT":
            return "Sub-object"
        case "CONNECTION_BELONGSTO":
        case "CONNECTION_CONTAINS":
            return "Entity"
        // case "CUSTOMENDPOINT":
        //     return "Endpoint"
        // case "EXTERNALENDPOINT":
        //     return "External endpoint"
        case "USER_ID":
            return "User ID"
        case "OBJECT_ID":
            return "Object ID"
        case "EMAIL":
            return "Email"
        case "PHONENUMBER":
            return "Phone number"
        case "USERSELF":
            return "User-self"
        default:
            return type
    }
}

function getTypeTooltipText(type: EntityField["type"]) {
    switch (type) {
        case "STRING":
            return "String: a sequence of characters (normal text). Examples include names, addresses, and messages."
        case "INT":
            return "Integer: A whole number, either positive or negative, with no decimal places. Examples include the number of items in a shopping cart or the user's age."
        case "FLOAT":
            return "Float: A number with a decimal point. Examples include the price of an item or the user's weight."
        case "DOUBLE":
            return "Double: A number with a decimal point (Similar to float, but can represent more precise values with greater range). Examples include the price of an item or the user's weight."
        case "BOOLEAN":
            return "Boolean: A value that is either true or false. Examples include whether a user is logged in or whether an item is in stock."
        case "DATE":
            return "Date: A date, in the calendar system, such as 2007-12-03. Examples include a user's birthday or the date an item was added to a shopping cart."
        case "TIME":
            return "Time: A date with time, in the 24-hour clock system, such as 2007-12-03 21:45:00. Examples include the time an item was added to a shopping cart or the time a user logged in."
        case "ENUM":
            return `Enum: List of predefined options. In programming, an enum (short for "enumeration") is a type of data that allows you to define a set of named values, or "constants", that represent a list of options or choices.`
        case "SUBOBJECT":
            return "Sub-object"
        case "CONNECTION_BELONGSTO":
        case "CONNECTION_CONTAINS":
            return "Entity: A reference to another entity. Examples include a user's shopping cart or a user's address."
        // case "CUSTOMENDPOINT":
        //     return "Endpoint: A reference to a custom endpoint."
        // case "EXTERNALENDPOINT":
        //     return "External endpoint: A reference to an external endpoint (URL)."
        case "USER_ID":
            return "User ID: A reference to a user."
        case "OBJECT_ID":
            return "Object ID: A reference to an object."
        case "USERSELF":
            return "User-self: An automatic field value. A reference to the user who is creating an object."
        case "EMAIL":
        case "PHONENUMBER":
        default:
            return undefined
    }
}

function renderValue(value: EntityField["defaultValue"]) {
    if (typeof value === "string") return value
    if (typeof value === "number") return value
    if (typeof value === "boolean") return value
    if (typeof value === "object" && value instanceof Date) return value.toISOString()
    return `${value}`
}

export { getTypeIcon, getTypeText, getTypeTooltipText }

export default function DataField(props: {
    field: EntityField
    sendFieldForm: (field?: EntityField) => void
    isFieldForm: boolean
    index: number
    isPermissionForm: boolean
    isSavingForm: boolean
    isDefaultField?: boolean
}) {
    const { field, sendFieldForm, isFieldForm, index, isPermissionForm, isSavingForm, isDefaultField } = props
    const [isHovered, setIsHovered] = useState(false)

    let dataFieldType = (
        <DataFieldTypeWrapper withValue={field.defaultValue || field.connectedEntityName || field.enumName || field.subObjectName ? true : false}>
            {getTypeIcon(field.type)}
            <DataFieldType>{getTypeText(field.type)}</DataFieldType>
            {field.connectedEntityName && <DataFieldTypeValue>{renderValue(field.connectedEntityName)}</DataFieldTypeValue>}
            {field.subObjectName && <DataFieldTypeValue>{renderValue(field.subObjectName)}</DataFieldTypeValue>}
            {field.enumName && <DataFieldTypeValue>{renderValue(field.enumName)}</DataFieldTypeValue>}

            {!field.connectedEntityName && !field.enumName && !field.subObjectName && field.defaultValue && (
                <DataFieldTypeValue>{renderValue(field.defaultValue)}</DataFieldTypeValue>
            )}
        </DataFieldTypeWrapper>
    )
    const tooltipText = getTypeTooltipText(field.type)
    if (tooltipText) {
        dataFieldType = (
            <Tooltip mouseEnterDelay={0.4} title={tooltipText} overlayInnerStyle={{ maxWidth: "600px" }}>
                {dataFieldType}
            </Tooltip>
        )
    }

    const mandatory = field.mandatory !== undefined ? field.mandatory : field.connectionUnderlyingField?.mandatory
    const isArray = field.isArray !== undefined ? field.isArray : field.connectionUnderlyingField?.isArray
    const mutable = field.mutable !== undefined ? field.mutable : field.connectionUnderlyingField?.mutable
    const isRemoteField = field.connectedEntityName !== undefined && field.connectionUnderlyingField === undefined

    return (
        <DataFieldWrapper isHovered={isHovered} index={index} actionable={!isRemoteField && !isDefaultField}>
            <DataFieldDetails>
                <DataFieldHeader>
                    {isRemoteField || isDefaultField ? (
                        <Tooltip
                            mouseEnterDelay={0.4}
                            title={`This field is an automatic field added because of specified remote field in "${field.connectedEntityName}"`}
                        >
                            <DataFieldTitle>
                                <i>{isRemoteField ? "auto " : "default "}</i>
                                {field.name}
                            </DataFieldTitle>
                        </Tooltip>
                    ) : (
                        <DataFieldTitle>{field.name}</DataFieldTitle>
                    )}
                    {dataFieldType}

                    {field.connectionUnderlyingField && (
                        <>
                            <DataFieldTitle>{field.connectionUnderlyingField.name}</DataFieldTitle>
                            <DataFieldTypeWrapper>
                                {getTypeIcon(field.connectionUnderlyingField.type)}
                                <DataFieldType>{getTypeText(field.connectionUnderlyingField.type)}</DataFieldType>
                            </DataFieldTypeWrapper>
                        </>
                    )}
                </DataFieldHeader>
                {field.description && <DataFieldDescription ellipsis={{ rows: 2, expandable: false }}>{field.description}</DataFieldDescription>}

                {!isRemoteField && !isDefaultField && (
                    <DataFieldswitchersWrapper>
                        <SwitchersGroup>
                            <Tooltip mouseEnterDelay={0.4} title={`This field is ${mandatory ? "mandatory" : "optional"} when creating user object.`}>
                                <Switcher selected={mandatory} inactive>
                                    {mandatory ? (
                                        <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayNormal }} />
                                    ) : (
                                        <CheckCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayNormal }} />
                                    )}
                                    {mandatory ? "is mandatory" : "is optional"}
                                </Switcher>
                            </Tooltip>
                            <Tooltip
                                mouseEnterDelay={0.4}
                                title={`This field ${
                                    isArray ? "can" : "cannot"
                                } contains more than one value. An array is a way of storing a collection of values or items in a specific order.`}
                            >
                                <Switcher selected={isArray} inactive>
                                    {isArray ? (
                                        <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayNormal }} />
                                    ) : (
                                        <CheckCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayNormal }} />
                                    )}
                                    {isArray ? "is array" : "is singular"}
                                </Switcher>
                            </Tooltip>
                            <Tooltip
                                mouseEnterDelay={0.4}
                                arrow={{ pointAtCenter: true }}
                                overlayInnerStyle={{ width: "600px" }}
                                title={
                                    mutable
                                        ? `(enabled) When something is described as "mutable", it means that it can be changed or altered later after it was initially created.`
                                        : `Immutable (opposite of mutable) means that data cannot be modified after it was created.`
                                    // `Think about a recipe for a soup. If you want to change the flavor or the ingredients, you can modify the recipe by adding or removing ingredients, changing the cooking time or temperature, or adjusting the proportions of the elements. In this case, the recipe is a mutable object because it can be changed by altering its components and characteristics.`
                                }
                            >
                                <Switcher selected={mutable} inactive>
                                    {mutable ? (
                                        <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayNormal }} />
                                    ) : (
                                        <CheckCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayNormal }} />
                                    )}
                                    {mutable ? "is mutable" : "is permanent"}
                                </Switcher>
                            </Tooltip>
                        </SwitchersGroup>
                    </DataFieldswitchersWrapper>
                )}
            </DataFieldDetails>

            {!isRemoteField && !isDefaultField && (
                <Button
                    disabled={isFieldForm || isPermissionForm || isSavingForm}
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onMouseEnter={() => {
                        setIsHovered(true)
                    }}
                    onMouseLeave={() => {
                        setIsHovered(false)
                    }}
                    onClick={() => {
                        setIsHovered(false)
                        sendFieldForm(field)
                    }}
                />
            )}
        </DataFieldWrapper>
    )
}

const DataFieldWrapper = styled(ItemWithFadeInAnimation)<{ isHovered: boolean; index: number; actionable?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: ${Spaces.normal};

    width: 100%;
    background-color: ${(props: { isHovered: boolean; actionable?: boolean }) =>
        props.actionable ? "white" : "unset"}; // props.isHovered ? Colors.grayLight :
    box-shadow: ${(props: { isHovered: boolean; actionable?: boolean }) =>
        props.actionable ? StyleHelpers.staticBoxShadow : "unset"}; // props.isHovered ? StyleHelpers.accentGlowShadow :
    border-radius: ${StyleHelpers.radiusMedium};
    padding: ${(props) => (props.actionable ? Spaces.large : Spaces.normal)};

    animation-delay: ${(props: { index: number }) => props.index * 0.15}s; /* delay animation start for each item */

    /* transform: ${({ isHovered }) => (isHovered ? "scale(1.01)" : "unset")}; */
`

const DataFieldDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: ${Spaces.normal};
`

const DataFieldHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: ${Spaces.medium};
`

const DataFieldTitle = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    i {
        font-size: 16px;
        color: ${Colors.grayDark};
    }
`
const DataFieldDescription = styled(Paragraph)`
    padding: 0 0 0 ${Spaces.xLarge};
    margin: 0 !important;
`

const DataFieldTypeWrapper = styled.div<{ withValue?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${(props: { withValue?: boolean }) => (props.withValue ? "0 0 0 7px" : "2px 7px")};
    gap: ${Spaces.small};
    border-radius: 2px;
    border: 1px solid ${Colors.grayNormal};
    background-color: ${Colors.grayLight};
`

const DataFieldType = styled.div``

const DataFieldTypeValue = styled.div`
    background-color: ${Colors.grayNormal};
    color: ${Colors.transparentWhiteBackground};
    /* font-weight: 600; */
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 7px;
    /* border-radius: 2px; */
    display: block;
`

const DataFieldswitchersWrapper = styled.div`
    margin-left: ${Spaces.xLarge};
`
