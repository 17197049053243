import { useState } from "react"
import styled from "styled-components"
import { Button, Badge, Tooltip } from "antd"
import { FilterOutlined, KeyOutlined, UserOutlined, EditOutlined, CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons"

import { Colors, Spaces, StyleHelpers, ItemWithFadeInAnimation } from "../../global"
import { Switcher } from "../../Switcher"
import type { AccessItem } from "../../../coreTypes/config"

export default function AccessPermissionItem(props: {
    entityName: string
    accessItem: AccessItem
    isFieldForm: boolean
    sendEditAccessPermissionItem: () => void
    index: number
}) {
    const { entityName, accessItem, isFieldForm, sendEditAccessPermissionItem, index } = props
    const [isHovered, setIsHovered] = useState(false)

    return (
        <AccessPermissionItemWrapper isHovered={isHovered} index={index}>
            <AccessPermissionItemHeader>
                <NameTag>
                    {accessItem.type == "USERGROUP" ? <UserOutlined /> : <KeyOutlined />}
                    <span>{accessItem.type == "USERGROUP" ? accessItem.userGroupName : accessItem.apiKeyName}</span>
                </NameTag>
                <Tooltip
                    mouseEnterDelay={0.4}
                    title={`Quantity of selected allowed fields for this user group or API key to "Create", "Read", "Update", "Delete"`}
                >
                    <CRUDWrapper>
                        <Badge
                            showZero
                            count={accessItem.fields === "ALL" ? "All" : accessItem.fields.length}
                            style={{
                                marginLeft: Spaces.small,
                                backgroundColor: Colors.grayLight,
                                borderColor: Colors.grayLight,
                                color: Colors.grayDark,
                            }}
                        />
                        <CRUDLights>
                            <Light
                                filled={accessItem.fields === "ALL" || accessItem.fields.find((f) => f.operations?.includes("CREATE")) !== undefined}
                            >
                                C
                            </Light>
                            <Light
                                filled={accessItem.fields === "ALL" || accessItem.fields.find((f) => f.operations?.includes("READ")) !== undefined}
                            >
                                R
                            </Light>
                            <Light
                                filled={accessItem.fields === "ALL" || accessItem.fields.find((f) => f.operations?.includes("UPDATE")) !== undefined}
                            >
                                U
                            </Light>
                            <Light
                                filled={accessItem.fields === "ALL" || accessItem.fields.find((f) => f.operations?.includes("DELETE")) !== undefined}
                            >
                                D
                            </Light>
                        </CRUDLights>
                    </CRUDWrapper>
                </Tooltip>

                {accessItem.rule?.type == "AND" ? (
                    <Tooltip
                        mouseEnterDelay={0.4}
                        title={`Restricted access to this "${entityName}" entity for user group with the additional individual user-based condition(-s)`}
                    >
                        <Switcher selected inactive>
                            <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayNormal }} />
                            conditioanlly restricted
                        </Switcher>
                    </Tooltip>
                ) : (
                    <Tooltip mouseEnterDelay={0.4} title={`Any user from specified user group has following access to this entity "${entityName}"`}>
                        <Switcher inactive>
                            <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayNormal }} />
                            without restrictions
                        </Switcher>
                    </Tooltip>
                )}

                <div className="actionWrapper">
                    <Button
                        disabled={isFieldForm}
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        onMouseEnter={() => {
                            setIsHovered(true)
                        }}
                        onMouseLeave={() => {
                            setIsHovered(false)
                        }}
                        onClick={() => {
                            setIsHovered(false)
                            sendEditAccessPermissionItem()
                        }}
                    />
                </div>
            </AccessPermissionItemHeader>

            {accessItem.rule?.type == "AND" && (
                <AccessPermissionItemBody>
                    <AccessPermissionItemBodyTitle>
                        <Badge
                            showZero
                            count={accessItem.rule.andConditions?.length}
                            style={{
                                marginLeft: Spaces.small,
                                backgroundColor: Colors.grayLight,
                                borderColor: Colors.grayLight,
                                color: Colors.grayDark,
                            }}
                        />
                        <FilterOutlined />
                        <AccessPermissionItemRuleName>{accessItem.rule.name}</AccessPermissionItemRuleName>
                    </AccessPermissionItemBodyTitle>
                    <AccessPermissionItemRuleDescription>{accessItem.rule.description}</AccessPermissionItemRuleDescription>
                </AccessPermissionItemBody>
            )}
        </AccessPermissionItemWrapper>
    )
}

const AccessPermissionItemWrapper = styled(ItemWithFadeInAnimation)<{ isHovered: boolean; index: number }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${Spaces.small};

    /* background-color: ${(props: { isHovered: boolean }) => (props.isHovered ? Colors.grayLight : "white")}; */
    background-color: white;
    box-shadow: ${StyleHelpers.staticBoxShadow};
    border-radius: ${StyleHelpers.radiusMedium};
    padding: ${Spaces.large};

    animation-delay: ${(props: { index: number }) => props.index * 0.15}s; /* delay animation start for each item */
`

const AccessPermissionItemHeader = styled.div`
    display: flex;
    direction: row;
    align-items: center;
    gap: ${Spaces.medium};
    width: 100%;

    div.actionWrapper {
        flex-grow: 1;
        align-self: stretch;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`

export const NameTag = styled.div`
    display: flex;
    direction: row;
    align-items: center;
    gap: ${Spaces.small};

    border: 1px solid ${Colors.grayNormal};
    border-radius: 5px;
    overflow: hidden;
    font-size: 14px;
    padding-right: ${Spaces.normal};
    color: ${Colors.grayDark};
    background-color: ${Colors.grayLight};

    span.anticon {
        padding: ${Spaces.small};
        /* border-radius: 20px; */
        color: ${Colors.background};
        background-color: ${Colors.grayNormal};
    }
`

const CRUDWrapper = styled.div`
    display: flex;
    direction: row;
    align-items: center;
    gap: 8px;

    border: 1px solid ${Colors.grayLight};
    border-radius: 20px;

    .ant-badge-count {
        margin-left: 0px !important;
    }
`

const CRUDLights = styled.div`
    display: flex;
    direction: row;
    align-items: center;
    gap: 1px;

    padding-right: ${Spaces.small};
`

const Light = styled.div<{ filled: boolean }>`
    /* padding: 2px 6px; */
    padding: 2px;
    border-radius: 20px;
    font-size: 12px;
    line-height: 16px;
    /* border: 1px solid ${Colors.grayNormal}; */
    color: ${(props) => (props.filled ? Colors.grayDark : Colors.grayLight)};
    /* background-color: ${(props) => (props.filled ? Colors.grayNormal : Colors.background)}; */
`

const AccessPermissionItemBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${Spaces.normal};
    margin: ${Spaces.normal} 0;
`

const AccessPermissionItemBodyTitle = styled.div`
    display: flex;
    direction: row;
    align-items: center;
    gap: 2px;
    padding-left: ${Spaces.medium};
`

const AccessPermissionItemRuleName = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: ${Colors.grayDark};
    padding-left: ${Spaces.small};
`

const AccessPermissionItemRuleDescription = styled.div`
    color: ${Colors.grayDark};
    padding-left: ${Spaces.xLarge};
    font-size: 14px;
`
