import logo from "../assets/logo.svg"
import appScreen from "../assets/app-screen.png"
import { Mask } from "./AuthPage"
import { Typography } from "antd"

const { Title, Paragraph } = Typography

export default function ActivationPage() {
    return (
        <Mask>
            <img src={logo} alt="quorini-logo" />
            <Title level={2} style={{ paddingTop: "0px", paddingBottom: "40px" }}>
                Welcome to project management app!
            </Title>
            <Paragraph style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                Thank you for signing up! Your account is currently pending activation by our administration team.
            </Paragraph>
            <Paragraph style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                Whether you are a new member or returning after a brief deactivation, we appreciate your patience during this process.
            </Paragraph>
            <Paragraph style={{ paddingTop: "0px", paddingBottom: "0px", fontWeight: "bold" }}>
                We will notify you via email once your account has been activated.
            </Paragraph>
            <Paragraph style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                In the meantime, feel free to explore our resources and community guidelines.
            </Paragraph>
            <Paragraph style={{ paddingTop: "0px", paddingBottom: "0px" }}>Thank you for being part of our community!</Paragraph>
            <img src={appScreen} alt="quorini-app-screen" style={{ width: "800px", paddingTop: "40px" }} />
        </Mask>
    )
}
