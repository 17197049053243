import React from "react"
import { CarryOutOutlined, CheckCircleFilled, CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { Tree, Tooltip, Popconfirm } from "antd"
import styled from "styled-components"
import type { DataNode } from "antd/es/tree"

import { Colors, Spaces, StyleHelpers } from "../../global"
import { AccessField, AccessItem, Config, EntityField } from "../../../coreTypes/config"
import { SwitchersGroup, Switcher } from "../../Switcher"

function lookupNestedOperations(fields: AccessField[], parentPath: string) {
    const fieldsChain = parentPath.split(".")
    for (let f = 0; f <= fields.length - 1; f++) {
        if (fields[f].fieldName === fieldsChain[0]) {
            let i = 1
            const operations = fields[f].operations
            let inner = fields[f].subfield
            while (true) {
                if (inner?.fieldName !== fieldsChain[i]) break
                if (i === fieldsChain.length - 1) {
                    return [
                        operations?.includes("CREATE") || false,
                        operations?.includes("READ") || false,
                        operations?.includes("UPDATE") || false,
                        operations?.includes("DELETE") || false,
                    ]
                }
                if (!inner.subfield) break
                inner = inner.subfield
                i++
            }
        }
    }
    return [false, false, false, false]
}

function getTreeNode(
    field: EntityField,
    accessItem: AccessItem,
    projectConfig: Config,
    changeFieldAccess: (fieldPath: string, accessType: string) => void,
    parentPath?: string
): DataNode[] {
    let isCreate = true
    let isRead = true
    let isUpdate = true
    let isDelete = true
    if (accessItem.fields !== "ALL") {
        if (parentPath) {
            ;[isCreate, isRead, isUpdate, isDelete] = lookupNestedOperations(
                accessItem.fields,
                `${parentPath}.${field.connectionUnderlyingField?.name ?? field.name}`
            )
        } else {
            const accessField = accessItem.fields.find(
                (accessField) => accessField.fieldName === field.name || accessField.fieldName === field.connectionUnderlyingField?.name
            )

            isCreate = accessField?.operations?.includes("CREATE") || false
            isRead = accessField?.operations?.includes("READ") || false
            isUpdate = accessField?.operations?.includes("UPDATE") || false
            isDelete = accessField?.operations?.includes("DELETE") || false
        }
    }

    const createSwitcher = (fieldName: string) => {
        const fields = accessItem.fields as AccessField[]
        const containsCreate = fields.findIndex((f) => f.operations?.includes("CREATE")) === -1 ? false : true

        let switcher = (
            <Switcher
                onClick={() => {
                    if ((!field.mandatory || (field.mandatory && field.defaultValue)) && containsCreate)
                        changeFieldAccess(parentPath ? parentPath + "." + fieldName : fieldName, "CREATE")
                }}
                selected={isCreate}
            >
                {isCreate ? (
                    <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                ) : (
                    <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                )}
                create
            </Switcher>
        )

        let popConfirmProps = {
            title: "?",
            description: "...",
            okText: "Confirm",
            cancelText: "Cancel",
        }

        if (field.mandatory && !field.defaultValue && isCreate)
            popConfirmProps = {
                title: "Disable creation for all fields?",
                description: "By disabling mandatory field (w/o default value) you will disable creation for all fields.",
                okText: "Disable all",
                cancelText: "Keep enabled",
            }

        if ((field.mandatory && !field.defaultValue && !isCreate) || !containsCreate)
            popConfirmProps = {
                title: "Enable creation for all mandatory fields?",
                description: "Enabling creation requires for all mandatory (w/o default value) fields to be enabled.",
                okText: "Enable all",
                cancelText: "Keep disabled",
            }

        if ((field.mandatory && !field.defaultValue) || !containsCreate) {
            switcher = (
                <Popconfirm
                    {...popConfirmProps}
                    placement="top"
                    onConfirm={() => {
                        changeFieldAccess(parentPath ? parentPath + "." + fieldName : fieldName, "CREATE")
                    }}
                    // onCancel={() => {}}
                >
                    {switcher}
                </Popconfirm>
            )
        }

        return switcher
    }

    const defaultNode = (fieldName: string, isMutable?: boolean, isDefaultField = false) => ({
        title: (
            <FieldRow>
                <div>{fieldName}</div>
                <SwitchersGroup>
                    {!isDefaultField ? createSwitcher(fieldName) : <div style={{ width: "80px" }}></div>}
                    <Switcher
                        onClick={() => {
                            changeFieldAccess(parentPath ? parentPath + "." + fieldName : fieldName, "READ")
                        }}
                        selected={isRead}
                    >
                        {isRead ? (
                            <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                        ) : (
                            <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                        )}
                        read
                    </Switcher>
                    {!isDefaultField ? (
                        <Tooltip mouseEnterDelay={0.4} title={!isMutable ? `Only mutable fields can be updated after being created` : ""}>
                            <Switcher
                                onClick={() => {
                                    isMutable && changeFieldAccess(parentPath ? parentPath + "." + fieldName : fieldName, "UPDATE")
                                }}
                                selected={isUpdate && isMutable}
                                inactive={!isMutable}
                            >
                                {isUpdate && isMutable ? (
                                    <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                                ) : (
                                    <CloseCircleOutlined
                                        style={{ fontSize: StyleHelpers.iconSize, color: isMutable ? Colors.primary : Colors.grayNormal }}
                                    />
                                )}
                                update
                            </Switcher>
                        </Tooltip>
                    ) : (
                        <div style={{ width: "80px" }}></div>
                    )}

                    <div style={{ width: "80px" }}></div>
                    {/* <Switcher
                        onClick={() => {
                            !field.mandatory && changeFieldAccess(parentPath ? parentPath + "." + fieldName : fieldName, "DELETE")
                        }}
                        selected={!field.mandatory ? isDelete : false}
                        inactive={field.mandatory}
                    >
                        {!field.mandatory && isDelete ? (
                            <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                        ) : (
                            <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: field.mandatory ? Colors.grayNormal : Colors.primary }} />
                        )}
                        delete
                    </Switcher> */}
                    {/* TODO: <Switcher selected={true}>
                            {true ? (
                                <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                            ) : (
                                <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                            )}
                            append
                        </Switcher> */}
                </SwitchersGroup>
            </FieldRow>
        ),
        key: parentPath ? `${parentPath}.${fieldName}` : fieldName,
        icon: <CarryOutOutlined />,
        selectable: false,
    })

    switch (field.type) {
        case "STRING":
        case "INT":
        case "FLOAT":
        case "DOUBLE":
        case "BOOLEAN":
        case "DATE":
        case "TIME":
        case "ENUM":
        case "OBJECT_ID":
        case "USER_ID":
        case "EMAIL":
        case "PHONENUMBER":
            return [defaultNode(field.name, field.mutable, Object.keys(field).length === 2)] // default fields have only name and type, that's why it's simple way to identify them here
        case "SUBOBJECT":
            let children: DataNode[] = []
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const subobject = projectConfig.subObjects![field.subObjectName!]
            for (const subfield of subobject.fields) {
                children = children.concat(
                    getTreeNode(subfield, accessItem, projectConfig, changeFieldAccess, parentPath ? `${parentPath}.${field.name}` : field.name)
                )
            }

            return [
                {
                    title: (
                        <FieldRow noSwitchers>
                            <div>{field.name}</div>
                        </FieldRow>
                    ),
                    key: parentPath ? `${parentPath}.${field.name}` : field.name,
                    icon: <CarryOutOutlined />,
                    selectable: false,
                    children: children,
                },
            ]
        case "CONNECTION_BELONGSTO":
        case "CONNECTION_CONTAINS":
        case "CUSTOMENDPOINT":
        case "EXTERNALENDPOINT":
            const treeArr: DataNode[] = []
            if (field.connectionUnderlyingField) {
                treeArr.push(defaultNode(field.connectionUnderlyingField.name, field.connectionUnderlyingField.mutable))
            }
            treeArr.push({
                title: (
                    <FieldRow noSwitchers>
                        <div>{field.name}</div>
                        <Tooltip mouseEnterDelay={0.4} title={`Manage permissions in the remote entity/user.`}>
                            <InfoCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                        </Tooltip>
                    </FieldRow>
                ),
                key: parentPath ? `${parentPath}.${field.name}` : field.name,
                icon: <CarryOutOutlined />,
                selectable: false,
            })
            return treeArr
        case "USERSELF":
            return [
                {
                    title: (
                        <FieldRow noSwitchers>
                            <div>{field.name}</div>
                            <Tooltip mouseEnterDelay={0.4} title={`Automatic field which sets userId as value of the user when object created.`}>
                                <InfoCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                            </Tooltip>
                        </FieldRow>
                    ),
                    key: parentPath ? `${parentPath}.${field.name}` : field.name,
                    icon: <CarryOutOutlined />,
                    selectable: false,
                },
            ]
        default:
            return [
                {
                    title: (
                        <FieldRow noSwitchers>
                            <div>{field.name}</div>
                            <Tooltip mouseEnterDelay={0.4} title={`Unknown field type`}>
                                <InfoCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                            </Tooltip>
                        </FieldRow>
                    ),
                    key: parentPath ? `${parentPath}.${field.name}` : field.name,
                    icon: <CarryOutOutlined />,
                    selectable: false,
                },
            ]
    }
}

export default function AllowedFieldsTree(props: {
    topLevelFields: EntityField[]
    projectConfig: Config
    accessItem: AccessItem
    changeFieldAccess: (fieldPath: string, accessType: string) => void
}) {
    const { topLevelFields, projectConfig, accessItem, changeFieldAccess } = props

    // console.log(accessItem)

    const isAllDelete =
        accessItem.fields !== "ALL" && accessItem.fields.filter((field) => field.operations?.includes("DELETE")).length === topLevelFields.length

    let treeData: DataNode[] = [
        {
            title: (
                <FieldRow>
                    <div>
                        <b>All object fields</b>
                    </div>
                    <SwitchersGroup>
                        {/* <Switcher
                            heavy
                            onClick={() => {
                                changeFieldAccess("ALL_FIELDS", "CREATE")
                            }}
                            selected={isAllCreate}
                        >
                            {isAllCreate ? (
                                <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                            ) : (
                                <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                            )}
                            create
                        </Switcher>
                        <Switcher
                            heavy
                            onClick={() => {
                                changeFieldAccess("ALL_FIELDS", "READ")
                            }}
                            selected={isAllRead}
                        >
                            {isAllRead ? (
                                <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                            ) : (
                                <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                            )}
                            read
                        </Switcher>
                        <Switcher
                            heavy
                            onClick={() => {
                                changeFieldAccess("ALL_FIELDS", "UPDATE")
                            }}
                            selected={isAllUpdate}
                        >
                            {isAllUpdate ? (
                                <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                            ) : (
                                <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                            )}
                            update
                        </Switcher> */}

                        <Tooltip
                            mouseEnterDelay={0.4}
                            title={
                                isAllDelete
                                    ? "(enabled) Allows deletion of objects for selected user group (Only complete object can be deleted, but not some of its fields)."
                                    : `Enable to allow deletion of objects for selected user group (Only complete object can be deleted, but not some of its fields).`
                            }
                        >
                            <Switcher
                                heavy
                                onClick={() => {
                                    changeFieldAccess("ALL_FIELDS", "DELETE")
                                }}
                                selected={isAllDelete}
                            >
                                {
                                    isAllDelete && <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                                    //  : (
                                    //     <CloseCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                                    // )
                                }
                                {isAllDelete ? "Deletion allowed" : "Allow to delete"}
                            </Switcher>
                        </Tooltip>
                    </SwitchersGroup>
                </FieldRow>
            ),
            key: "ALL_FIELDS",
            icon: <CarryOutOutlined />,
            selectable: false,
        },
    ]

    for (const field of topLevelFields) {
        treeData = treeData.concat(getTreeNode(field, accessItem, projectConfig, changeFieldAccess))
    }

    return (
        <TreeWrapper>
            <Tree
                style={{
                    background: Colors.background,
                    width: "100%",
                }}
                showLine={true}
                showIcon={false}
                defaultExpandAll
                treeData={treeData}
            />
        </TreeWrapper>
    )
}

export const TreeWrapper = styled.div`
    width: 100%;
    .ant-tree,
    .ant-tree-treenode,
    .ant-tree-node-content-wrapper {
        width: 100%;
    }
    .ant-tree-node-content-wrapper {
        & :hover {
            cursor: default;
        }
    }
`

const FieldRow = styled.div<{ noSwitchers?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: ${Spaces.normal};
    padding: ${Spaces.small} 0;

    & > div:first-child {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-self: stretch;
        font-size: 16px;
        margin-top: ${(props: { noSwitchers?: boolean }) => (props.noSwitchers ? 0 : "3px")};
    }
`
