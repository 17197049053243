import { useEffect, useState } from "react"
import { useMachine } from "@xstate/react"
import { Button, Typography, Dropdown, Input, Tooltip, Badge, Popconfirm } from "antd"
import styled from "styled-components"
import {
    RedoOutlined,
    RollbackOutlined,
    AppstoreAddOutlined,
    EllipsisOutlined,
    SaveOutlined,
    CheckCircleFilled,
    DeleteOutlined,
    LoadingOutlined,
    CheckCircleOutlined,
    CheckOutlined,
} from "@ant-design/icons"

import { SwitchersGroup, Switcher } from "../components/Switcher"
import { Centered, Colors, PageWrapper, Spaces, StyleHelpers } from "../components/global"
import { FormHeader, MoreWrapper, SecondaryButton } from "../components/Designer/DataEntitiesTab/EntitiesFormWithFields"
import { projectsMachine } from "../machines/ProjectsMachine"
import { NotificationInstance } from "antd/es/notification/interface"
import { ProjectCard, ProjectCardContent } from "../components/Page/ProjectCard"

const { TextArea } = Input
const { Title } = Typography

export default function Projects(props: { notificationsApi: NotificationInstance }) {
    const [isHovered, setIsHovered] = useState(-1)
    const [current, send] = useMachine(projectsMachine)

    useEffect(() => {
        if (!current.context.notificationsApi) {
            send({ type: "UPDATE_NOTIFICATIONS_API", notificationsApi: props.notificationsApi })
        }
    }, [current])

    const menuItems: any = []
    if (
        !current.context.projectForm.originalProject ||
        (current.context.projectForm.originalProject &&
            JSON.stringify(current.context.projectForm.originalProject) !== JSON.stringify(current.context.projectForm.project))
    )
        menuItems.push({
            key: "cancel",
            label: (
                <Popconfirm
                    title="Are you sure you want to cancel without saving changes?"
                    onConfirm={() => send("CANCEL_PROJECT")}
                    okText="Yes"
                    cancelText="No"
                >
                    cancel
                </Popconfirm>
            ),
            icon: <RollbackOutlined />,
        })

    if (current.context.projectForm.originalProject?.name) {
        menuItems.push({
            key: "delete",
            label: (
                <Popconfirm
                    title="Are you sure you want to delete?"
                    onConfirm={() => send("DELETE_PROJECT", { projectId: current.context.projectForm.project._id })}
                    okText="Yes"
                    cancelText="No"
                >
                    delete
                </Popconfirm>
            ),
            icon: <DeleteOutlined />,
        })
    }

    if (current.matches("globalLoading"))
        return (
            <PageWrapper>
                <Centered vertical>
                    <LoadingOutlined style={{ fontSize: "75px", color: Colors.primary }} />
                    <Title level={2}>Loading list of projects...</Title>
                </Centered>
            </PageWrapper>
        )

    if (current.matches("error"))
        return (
            <PageWrapper>
                <Centered vertical>
                    <Title level={2}>Error loading projects: try again...</Title>
                    <Button
                        type="primary"
                        icon={<RedoOutlined />}
                        onClick={() => {
                            send("TRY_AGAIN")
                        }}
                    >
                        Load projects
                    </Button>
                </Centered>
            </PageWrapper>
        )

    const menuItemAction = (menuItem: any) => {
        switch (menuItem.key) {
            case "delete":
                send("DELETE_PROJECT", { projectId: current.context.projectForm.project._id })
                break
            case "cancel":
                send("CANCEL_PROJECT")
                break
        }
    }

    return (
        <PageWrapper>
            <PageContainer>
                <Title level={2}>My Projects</Title>
                <ProjectsContainer>
                    {current.matches("projectForm") ? (
                        <NewProjectForm isHovered={isHovered == 0}>
                            <FormHeader style={{ display: "flex", boxShadow: "none", marginBottom: Spaces.small }}>
                                <Input
                                    placeholder="Project name"
                                    title="Project name"
                                    size="large"
                                    value={current.context.projectForm.project.name}
                                    autoFocus
                                    onChange={(e) => {
                                        send("CHANGE_PROJECT_NAME", { projectName: e.target.value })
                                    }}
                                />

                                {menuItems.length > 1 && (
                                    <MoreWrapper>
                                        <Dropdown
                                            menu={{
                                                items: menuItems,
                                            }}
                                            placement="bottomRight"
                                        >
                                            <EllipsisOutlined
                                                onMouseEnter={() => {
                                                    setIsHovered(0)
                                                }}
                                                onMouseLeave={() => {
                                                    setIsHovered(-1)
                                                }}
                                                style={{ fontSize: "20px", color: Colors.grayDark }}
                                            />
                                        </Dropdown>
                                    </MoreWrapper>
                                )}
                                {menuItems.length == 1 && (
                                    <MoreWrapper>
                                        <Popconfirm
                                            title="Are you sure you want to perform this action?"
                                            onConfirm={() => {
                                                menuItemAction(menuItems[0])
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type="text" shape="circle" icon={menuItems[0].icon} />
                                        </Popconfirm>
                                    </MoreWrapper>
                                )}

                                {!current.context.projectForm.originalProject ||
                                (current.context.projectForm.originalProject &&
                                    JSON.stringify(current.context.projectForm.originalProject) !==
                                        JSON.stringify(current.context.projectForm.project)) ? (
                                    <SecondaryButton
                                        disabled={!current.context.projectForm.project.name || !current.context.projectForm.project.publicName}
                                        loading={current.matches("projectForm.savingProject")}
                                        type="primary"
                                        shape="round"
                                        icon={<CheckOutlined />}
                                        onClick={() => {
                                            send("SAVE_PROJECT")
                                            setIsHovered(-1)
                                        }}
                                        onMouseEnter={() => {
                                            setIsHovered(0)
                                        }}
                                        onMouseLeave={() => {
                                            setIsHovered(-1)
                                        }}
                                        isSolid
                                    >
                                        Save
                                    </SecondaryButton>
                                ) : (
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<RollbackOutlined />}
                                        onClick={() => {
                                            send("CANCEL_PROJECT")
                                            setIsHovered(-1)
                                        }}
                                        onMouseEnter={() => {
                                            setIsHovered(0)
                                        }}
                                        onMouseLeave={() => {
                                            setIsHovered(-1)
                                        }}
                                    />
                                )}
                            </FormHeader>

                            <FormBasicInfo>
                                <TextArea
                                    placeholder="(optional) Corresponding description of the project..."
                                    title="Corresponding description of the project..."
                                    value={current.context.projectForm.project.description}
                                    onChange={(e) => {
                                        send("CHANGE_DESCRIPTION", { description: e.target.value })
                                    }}
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                />
                                <Input
                                    placeholder="Public name (will be displayed to the public within transactional emails (invitations, verifications, etc.))"
                                    title="Public name (will be displayed to the public within transactional emails (invitations, verifications, etc.))"
                                    value={current.context.projectForm.project.publicName}
                                    onChange={(e) => {
                                        send("CHANGE_PROJECT_PUBLIC_NAME", { publicName: e.target.value })
                                    }}
                                />

                                <SwitchersGroup>
                                    <Tooltip
                                        mouseEnterDelay={0.4}
                                        title="(enabled by default) Allows your end-user to sign up to this project's API."
                                    >
                                        <Switcher selected inactive>
                                            <CheckCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayNormal }} />
                                            Authentication module included
                                        </Switcher>
                                    </Tooltip>
                                </SwitchersGroup>
                            </FormBasicInfo>
                        </NewProjectForm>
                    ) : (
                        <ProjectCardContent isHovered={isHovered === 0} isNew>
                            <Button
                                type="dashed"
                                size="large"
                                icon={<AppstoreAddOutlined />}
                                onMouseEnter={() => {
                                    setIsHovered(0)
                                }}
                                onMouseLeave={() => {
                                    setIsHovered(-1)
                                }}
                                onClick={() => {
                                    setIsHovered(-1)
                                    send("SELECT_PROJECT")
                                }}
                            >
                                Create new project
                            </Button>
                        </ProjectCardContent>
                    )}

                    {current.context.projects.map((project, index) =>
                        project.isSample ? (
                            <Badge.Ribbon key={index} text="Sample" color={Colors.primary} style={{ marginTop: "-4px" }}>
                                <ProjectCard project={project} isHovered={isHovered} setIsHovered={setIsHovered} index={index} current={current} />
                            </Badge.Ribbon>
                        ) : (
                            <ProjectCard
                                key={index}
                                project={project}
                                isHovered={isHovered}
                                setIsHovered={setIsHovered}
                                index={index}
                                current={current}
                            />
                        )
                    )}
                </ProjectsContainer>
            </PageContainer>
        </PageWrapper>
    )
}

const PageContainer = styled.div`
    max-width: 1400px;
    padding: ${Spaces.normal} 50px;
`

const ProjectsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: start;
    align-items: start;
    gap: ${Spaces.large};
`

const NewProjectForm = styled.div<{ isHovered: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${Spaces.small};
    width: 620px;
    height: 250px;
    padding: ${Spaces.large};
    border-radius: ${StyleHelpers.radiusMedium};
    /* background-color: ${({ isHovered }) => (isHovered ? Colors.grayLight : Colors.background)}; */
    background-color: ${Colors.background};
    border: 2px solid ${Colors.primary};
    box-shadow: ${StyleHelpers.accentGlowShadow};
`

const FormBasicInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 ${Spaces.normal};
    gap: ${Spaces.medium};

    width: 100%;
    padding-left: 50px;
`
