import { FC, useEffect } from "react"
import { Form, Steps, Button, Select, Typography, Input, Tooltip } from "antd"
import { Colors, Spaces, StyleHelpers } from "../../global"
import { QuestionCircleOutlined, UserOutlined } from "@ant-design/icons"
import { OptionItemWrapper } from "../../Designer/FieldForm"
import { NotificationInstance } from "antd/es/notification/interface"
import styled from "styled-components"
import { SimulatorMachineSend, SimulatorMachineState } from "../../../machines/SimulatorMachine"
import { MutationFormWrapperCollapsible } from "../ApiSimulatorTab/EndpointsComponents"
import { MutationForm } from "../ApiSimulatorTab/SignUpProfile"

const { Option, OptGroup } = Select
const { Text } = Typography

interface Props {
    current: SimulatorMachineState
    send: SimulatorMachineSend
    notificationsApi: NotificationInstance
}

export const EmailInvitationSignupForm: FC<Props> = ({ current, send, notificationsApi }: Props) => {
    const [form] = Form.useForm()

    if (!current.context.notificationsApi) {
        send({ type: "UPDATE_NOTIFICATIONS_API", notificationsApi: notificationsApi })
    }

    const usergroupFields = current.context.projectConfig?.users.find(
        (u: any) => u?.groupName === current.context.gqlSimulator?.invitationEndpoint?.userGroup
    )?.userData.fields

    const steps = [
        {
            title: <Tooltip title="1st step: Sender provides the new user's email address to send the invitation to.">Email address</Tooltip>,
            content: (
                <FormWrapper
                    key="emailAddress"
                    onFinish={() => {
                        send("GO_PROFILE")
                    }}
                >
                    <Form.Item
                        name="usergroup"
                        // rules={[{ required: true, message: "Please select usergroup!" }]}
                        style={{ marginBottom: Spaces.xLarge }}
                    >
                        <Select
                            placeholder="Select User group..."
                            title="Select User group..."
                            style={{ width: 225 }}
                            allowClear
                            disabled={current.context.gqlSimulator.visibleInvitationUserGroups?.length === 0}
                            value={current.context.gqlSimulator.invitationEndpoint?.userGroup}
                            onSelect={(userGroup) => {
                                send("GO_SIGNUP")
                                send({ type: "SELECT_USER", userGroup })
                            }}
                            size="large"
                        >
                            {current.context.gqlSimulator.visibleInvitationUserGroups && (
                                <OptGroup label="User group" key="user group">
                                    {current.context.gqlSimulator.visibleInvitationUserGroups.map((ug) => (
                                        <Option value={ug} key={ug}>
                                            <OptionItemWrapper>
                                                <UserOutlined
                                                    style={{
                                                        width: StyleHelpers.iconSize,
                                                        color: Colors.grayDark,
                                                    }}
                                                />
                                                {`${ug}`}
                                            </OptionItemWrapper>
                                        </Option>
                                    ))}
                                </OptGroup>
                            )}
                        </Select>
                        <Tooltip title="If you do not see user group you want to invite, then you need to modify your project configuration in Designer mode.">
                            <QuestionCircleOutlined style={{ marginLeft: Spaces.normal, cursor: "help" }} />
                        </Tooltip>
                    </Form.Item>
                    <Text type="secondary">Provide the new user's email address to send the invitation to:</Text>
                    <Form.Item name="email" rules={[{ required: true, message: "Please input email address of your application's end-user!" }]}>
                        <Input
                            placeholder="User email address"
                            title="User email address"
                            size="large"
                            autoComplete="off"
                            disabled={
                                current.context.gqlSimulator.visibleInvitationUserGroups?.length == 0 ||
                                !current.matches("apiUsers.emailInvitation.signup.emailAddress")
                            }
                            value={current.context.gqlSimulator.invitationEndpoint?.email}
                            onChange={(e) => send({ type: "SET_INVITATION_EMAIL", email: e.target.value })}
                        />
                    </Form.Item>
                    <Button
                        htmlType="submit"
                        loading={current.matches("simulatorWrapper.gqlSimulator.sendingInvitation")}
                        disabled={
                            current.context.gqlSimulator.visibleInvitationUserGroups?.length == 0 ||
                            !current.context.gqlSimulator.invitationEndpoint?.email ||
                            !current.context.gqlSimulator.invitationEndpoint?.userGroup
                        }
                        type="primary"
                        size="large"
                        style={{ width: "140px", margin: "0 auto", marginRight: "0" }}
                    >
                        Send invitation
                    </Button>
                </FormWrapper>
            ),
            isActive: [
                "apiUsers.emailInvitation.signup.emailAddress",
                "apiUsers.emailInvitation.sessions",
                "apiUsers.emailInvitation.login.loginForm",
                "apiUsers.emailInvitation.login.logingIn",
            ].some(current.matches),
        },
        {
            title: <Tooltip title="2nd step: Recipient fulfills his profile details.">Profile</Tooltip>,
            content: (
                <FormWrapper key="profile" form={form}>
                    <MutationFormWrapperCollapsible>
                        <MutationForm
                            form={form}
                            setMutationFormValues={(values: any) => {
                                send({ type: "SET_PROFILE", values: values })
                            }}
                            fields={usergroupFields}
                            projectConfig={current.context.projectConfig}
                        />
                    </MutationFormWrapperCollapsible>

                    <div style={{ margin: "0 auto", marginRight: "0" }}>
                        <Button
                            disabled={current.matches("apiUsers.emailInvitation.signup.profile.creatingUser")}
                            size="large"
                            style={{ width: "140px", marginRight: Spaces.normal }}
                            onClick={() => {
                                send("GO_EMAIL_ADDRESS")
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            loading={current.matches("apiUsers.emailInvitation.signup.profile.creatingUser")}
                            type="primary"
                            htmlType="submit"
                            size="large"
                            style={{ width: "140px" }}
                            onClick={async () => {
                                try {
                                    await form.validateFields()
                                    form.submit()
                                    send("SEND_INVITATION")
                                } catch (error) {
                                    console.error("Validation failed:", error)
                                }
                            }}
                        >
                            Finish profile
                        </Button>
                    </div>
                </FormWrapper>
            ),
            isActive: ["apiUsers.emailInvitation.signup.profile.profileForm", "apiUsers.emailInvitation.signup.profile.creatingUser"].some(
                current.matches
            ),
        },
        {
            title: <Tooltip title="3rd step: Recipient verifies email address and sets new password.">Confirmation</Tooltip>,
            content: (
                <FormWrapper
                    key="confirmation"
                    onFinish={() => {
                        send("ACCEPT_INVITATION")
                    }}
                >
                    <Form.Item
                        label={
                            <span style={{ minWidth: 110 }}>
                                Invitation code
                                <Tooltip title="Check Recipient's email inbox for a verification code.">
                                    <QuestionCircleOutlined style={{ marginLeft: Spaces.small, cursor: "help" }} />
                                </Tooltip>
                            </span>
                        }
                        name="invitationCode"
                        colon={false}
                        rules={[{ required: true, message: "Please input code!" }]}
                        labelAlign="left"
                    >
                        <Input
                            type="text"
                            placeholder="Invitation code"
                            autoComplete="off"
                            style={{ maxWidth: "300px" }}
                            onChange={(e) => send({ type: "SET_ACCEPT_INVITATION_CODE", invitationCode: e.target.value })}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<span style={{ minWidth: 110 }}>New password</span>}
                        name="password"
                        colon={false}
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                            {
                                pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).+$/,
                                message: (
                                    <>
                                        Should contain at least 1 uppercase,
                                        <br />1 lowercase, 1 digit and 1 special charecter.
                                    </>
                                ),
                            },
                            {
                                min: 8,
                                message: "Should be at least 8 characters.",
                            },
                        ]}
                        labelAlign="left"
                    >
                        <Input.Password
                            type="password"
                            placeholder="New password"
                            autoComplete="new-password"
                            style={{ maxWidth: "300px" }}
                            onChange={(e) => send({ type: "SET_ACCEPT_INVITATION_PASSWORD", password: e.target.value })}
                        />
                    </Form.Item>
                    <div style={{ margin: "0 auto", marginRight: "0" }}>
                        <Button
                            disabled={current.matches("apiUsers.emailInvitation.signup.confirmation.confirmingInvitation")}
                            size="large"
                            style={{ width: "140px", marginRight: Spaces.normal }}
                            onClick={() => {
                                send("GO_PROFILE")
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            loading={current.matches("apiUsers.emailInvitation.signup.confirmation.confirmingInvitation")}
                            htmlType="submit"
                            type="primary"
                            size="large"
                            style={{ width: "140px" }}
                        >
                            Finish sign-up
                        </Button>
                    </div>
                </FormWrapper>
            ),
            isActive: [
                "apiUsers.emailInvitation.signup.confirmation.confirmForm",
                "apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
            ].some(current.matches),
        },
    ]

    const stepItems = steps.map((item) => ({ key: item.title, title: item.title }))
    const currentStep = steps.findIndex((step) => step.isActive)

    return (
        <SignupFormWrapper>
            <Steps style={{ margin: `${Spaces.large} 0px` }} current={currentStep} items={stepItems} />
            <div style={{ padding: "0 5px" }}>{steps[currentStep]?.content}</div>
        </SignupFormWrapper>
    )
}

const FormWrapper = styled(Form)`
    display: flex;
    flex-direction: column;
`
const SignupFormWrapper = styled.div`
    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: white !important;
    }
`
