import { useContext, useEffect } from "react"
import { Button, Input, Typography, Form } from "antd"
import { KeyOutlined, LoadingOutlined, LockOutlined, UserOutlined } from "@ant-design/icons"
import { useActor } from "@xstate/react"
import { useSearchParams } from "react-router-dom"
import styled from "styled-components"

import { GlobalStateContext } from ".."
import { Colors, Spaces } from "../components/global"
import logo from "../assets/logo.svg"
import { Selector, SelectorOption } from "../components/Designer/AccessPermissions/AccessPermissionForm"

const { Title } = Typography

export default function AuthPage() {
    const globalServices = useContext(GlobalStateContext)
    const [state, send] = useActor(globalServices.authService)

    const [URLParams, setURLParams] = useSearchParams()
    if (URLParams.get("mode") === "sign-up" && state.matches("authPage.logInForm")) send("SWITCH_AUTH")

    return (
        <Mask>
            <img src={logo} alt="quorini-logo" />

            <Title level={2} style={{ paddingTop: "0px", paddingBottom: "40px" }}>
                Project management app
            </Title>

            {state.matches("checkAuth") && <LoadingOutlined style={{ fontSize: "75px", color: Colors.primary }} />}

            {["authPage.logInForm", "authPage.signUpForm", "authPage.loggingIn", "authPage.signingUp"].some(state.matches) && (
                <FloatingCenterBlock>
                    <Selector
                        onClick={() => {
                            send("SWITCH_AUTH")
                            URLParams.delete("mode")
                            setURLParams(URLParams)
                        }}
                    >
                        <SelectorOption selected={["authPage.logInForm", "authPage.loggingIn"].some(state.matches)}>
                            <UserOutlined /> Log in
                        </SelectorOption>
                        <SelectorOption selected={["authPage.signUpForm", "authPage.signingUp"].some(state.matches)}>
                            <KeyOutlined /> Sign up
                        </SelectorOption>
                    </Selector>

                    <Form
                        onFinish={() => {
                            if (state.matches("authPage.logInForm")) {
                                send("LOG_IN")
                            } else {
                                send("SIGN_UP")
                            }
                        }}
                        autoComplete="off"
                    >
                        <Form.Item name="username" rules={[{ required: true, message: "Please input your email address!" }]}>
                            <Input
                                disabled={state.matches("authPage.loggingIn") || state.matches("authPage.signingUp")}
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Email address..."
                                title="Email address..."
                                onChange={(e) => {
                                    send({ type: "SET_USERNAME", username: e.target.value })
                                }}
                                size="large"
                                style={{ width: "300px" }}
                                autoFocus
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your password!",
                                },
                                {
                                    pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).+$/,
                                    message: "Should contain at least 1 uppercase, 1 lowercase, 1 digit and 1 special charecter.",
                                },
                                {
                                    min: 8,
                                    message: "Should be at least 8 characters.",
                                },
                            ]}
                        >
                            <Input.Password
                                disabled={state.matches("authPage.loggingIn") || state.matches("authPage.signingUp")}
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="Password..."
                                title="Password..."
                                onChange={(e) => {
                                    send({ type: "SET_PASSWORD", password: e.target.value })
                                }}
                                size="large"
                                style={{ width: "300px" }}
                            />
                        </Form.Item>

                        <ButtonsWrapper>
                            <Button
                                type="text"
                                disabled={state.matches("authPage.loggingIn") || state.matches("authPage.signingUp")}
                                onClick={() => {
                                    send("TO_RESET_PASSWORD")
                                }}
                            >
                                Forgot password?
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={state.matches("authPage.loggingIn") || state.matches("authPage.signingUp")}
                            >
                                {["authPage.logInForm", "authPage.loggingIn"].some(state.matches) ? "Log in" : "Sign up"}
                            </Button>
                        </ButtonsWrapper>
                    </Form>
                </FloatingCenterBlock>
            )}

            {state.matches("authPage.confirmEmailForm") && (
                <Form
                    onFinish={() => {
                        send("VERIFY_EMAIL")
                    }}
                    autoComplete="off"
                    style={{ maxWidth: "500px", display: "flex", flexDirection: "column", alignItems: "center" }}
                >
                    <Title level={5} style={{ margin: "5px auto 15px" }}>
                        We sent you an email with the verification code.
                        <br />
                        Please check your inbox (or spam in case you haven’t received it)
                    </Title>
                    <Form.Item name="emailConfirmationCode" rules={[{ required: true, message: "Please input verification code!" }]}>
                        <Input
                            disabled={["authPage.resendingEmail", "authPage.confirmingResetPassword"].some(state.matches)}
                            placeholder="Verification code"
                            title="Verification code"
                            onChange={(e) => {
                                send({ type: "SET_VERIFICATION_CODE", emailConfirmationCode: e.target.value })
                            }}
                            size="large"
                            style={{ width: "300px" }}
                            autoFocus
                        />
                    </Form.Item>

                    <div>
                        <Button
                            type="text"
                            onClick={() => {
                                send("RESEND_EMAIL")
                            }}
                            loading={state.matches("authPage.resendingEmail")}
                            disabled={state.matches("authPage.confirmingResetPassword")}
                            style={{ marginRight: "15px" }}
                        >
                            Resend email
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={state.matches("authPage.confirmingResetPassword")}
                            disabled={state.matches("authPage.resendingEmail")}
                        >
                            Confirm email
                        </Button>
                    </div>
                </Form>
            )}

            {state.matches("authPage.resetPasswordForm") && (
                <Form
                    onFinish={() => {
                        send("REQUEST_RESET_PASSWORD")
                    }}
                    autoComplete="off"
                >
                    <Form.Item name="username" rules={[{ required: true, message: "Please input your email address!" }]}>
                        <Input
                            disabled={state.matches("authPage.requestingResetPassword")}
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Email address..."
                            title="Email address..."
                            onChange={(e) => {
                                send({ type: "SET_USERNAME", username: e.target.value })
                            }}
                            size="large"
                            style={{ width: "300px" }}
                            autoFocus
                        />
                    </Form.Item>

                    <ButtonsWrapper>
                        <Button type="primary" htmlType="submit" loading={state.matches("authPage.requestingResetPassword")}>
                            Reset password
                        </Button>
                    </ButtonsWrapper>
                </Form>
            )}

            {state.matches("authPage.confirmResetForm") && (
                <Form
                    onFinish={() => {
                        send("CONFIRM_RESET_PASSWORD")
                    }}
                    autoComplete="off"
                >
                    <Form.Item name="resetPasswordConfirmationCode" rules={[{ required: true, message: "Please input verification code!" }]}>
                        <Input
                            disabled={state.matches("authPage.confirmingResetPassword")}
                            placeholder="Verification code"
                            title="Verification code"
                            onChange={(e) => {
                                send({ type: "SET_RESET_CODE", resetPasswordConfirmationCode: e.target.value })
                            }}
                            size="large"
                            style={{ width: "300px" }}
                            autoFocus
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                            {
                                pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).+$/,
                                message: "Should contain at least 1 uppercase, 1 lowercase, 1 digit and 1 special charecter.",
                            },
                            {
                                min: 8,
                                message: "Should be at least 8 characters.",
                            },
                        ]}
                    >
                        <Input.Password
                            disabled={state.matches("authPage.confirmingResetPassword")}
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="Password..."
                            title="Password..."
                            onChange={(e) => {
                                send({ type: "SET_PASSWORD", password: e.target.value })
                            }}
                            size="large"
                            style={{ width: "300px" }}
                        />
                    </Form.Item>

                    <ButtonsWrapper>
                        <Button type="primary" htmlType="submit" loading={state.matches("authPage.confirmingResetPassword")}>
                            Set new password
                        </Button>
                    </ButtonsWrapper>
                </Form>
            )}
        </Mask>
    )
}

export const Mask = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: ${Colors.background};
    flex-direction: column;
    align-items: center;
    padding-top: 150px;
`

const FloatingCenterBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${Spaces.xLarge};
    max-width: 300px;
`

const ButtonsWrapper = styled.div`
    margin-top: 24px; // the same as input has
`
